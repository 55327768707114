import React from "react";
import styled from "styled-components";

const FullScreenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const FullScreenDialog = ({ dialog }) => {
  if (dialog) {
    return (
      <FullScreenWrapper>
        <div>{dialog}</div>
      </FullScreenWrapper>
    );
  }
  return null;
};
