import React from "react";
import styled from "styled-components/macro";
import { Icon } from "../../components/Icon/Icon";
import { Link } from "react-router-dom";
import { slugging } from "../../helpers";

export const TopicsOverviewPage = ({ topics, company }) => {
  const companyBranding = company.branding;
  const categories = topics.reduce((categories, topic) => {
    const category = topic.category;
    const previousTopics = categories[category] || [];

    return {
      ...categories,
      [category]: [...previousTopics, topic],
    };
  }, {});

  const sections = Object.entries(categories);

  return (
    <div
      style={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
      }}
    >
      <PageHeader
        style={{
          backgroundColor: companyBranding.brandColor,
          color: companyBranding.invertedTextColor,
          alignSelf: "stretch",
        }}
      >
        <PageHeaderTitle>
          {company.name ? `${company.name}` : ""} topics
        </PageHeaderTitle>
      </PageHeader>

      <Categories>
        {sections.map((categoriesArray, index) => {
          const [categoryTitle, topics] = categoriesArray;
          return (
            <Category key={index}>
              <CategoryTitle>
                {/* Using Regex to get rid of the "1." at the beginning of title */}
                {categoryTitle.replace(/^\d+\. +/, "")}
              </CategoryTitle>

              <TopicsList>
                {topics.map(topic => (
                  <TopicItem key={topic._id}>
                    <Link to={`/topics/${slugging(topic.title)}`}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <IconWrapper>
                          <Icon
                            name={topic.icon}
                            className="icon"
                            color={companyBranding.brandColor}
                          />
                        </IconWrapper>
                        <TopicName>{topic.title}</TopicName>
                      </div>
                    </Link>
                  </TopicItem>
                ))}
              </TopicsList>
            </Category>
          );
        })}
      </Categories>
    </div>
  );
};

const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;

  position: sticky;
  top: 0;
  z-index: 1;
`;

const PageHeaderTitle = styled.h1`
  font-family: "Muli", sans-serif;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;
  font-size: 30px;

  max-width: 375px;
  margin: auto;
`;

const Categories = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 18px;

  width: 375px;
`;

const Category = styled.section`
  width: 100%;
  height: 100%;
  margin-bottom: 18px;
`;

const CategoryTitle = styled.h2`
  font-family: "Muli", sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: #2a2135;
`;

const TopicsList = styled.ul`
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  list-style: none;
  padding: 0px;
`;

const TopicItem = styled.li`
  padding: 0;
  margin: 0;
  a {
    min-height: 60px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 18px;
    display: block;
    text-decoration: none;
    font-family: "Muli", sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #2a2135;

    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon {
    margin-right: 15px;
  }
`;

const TopicName = styled.div`
  flex: 1;
`;
