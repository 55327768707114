const formatPrice = price => {
  if (price === undefined || price === null) {
    return null;
  }

  return (price / 100)
    .toFixed(2)
    .replace(",", "")
    .replace(".", ",")
    .replace(/,00$/, "");
};

const getVatAmount = price => {
  return price * 0.21;
};

const getIntervalText = origInterval => {
  const interval = { ...origInterval };

  while (interval["days"] >= 7) {
    interval["days"] -= 7;
    interval["weeks"] += 1;
  }
  while (interval["weeks"] >= 52) {
    interval["weeks"] -= 52;
    interval["years"] += 1;
  }
  while (interval["months"] >= 12) {
    interval["months"] -= 12;
    interval["years"] += 1;
  }

  const periods = [
    ["years", "year", "years"],
    ["months", "month", "months"],
    ["weeks", "week", "weeks"],
    ["days", "day", "days"],
  ];

  let parts = [];

  periods.forEach(period => {
    const val = interval[period[0]];
    if (val === 1) {
      parts.push("1 " + period[1]);
    } else if (val > 1) {
      parts.push(val + " " + period[2]);
    }
  });

  let str = parts.slice(0, -1).join(", ") + " and " + parts.slice(-1);

  if (parts.length === 1) {
    str = parts[0];
  }

  return str;
};

const getIntervalTexts = (interval, billingLoops) => {
  const multipliedInterval = [];

  Object.keys(interval).forEach(key => {
    multipliedInterval[key] = interval[key] * billingLoops;
  });

  return {
    intervalText: getIntervalText(interval),
    multipliedIntervalText: getIntervalText(multipliedInterval),
  };
};

const slugging = string => {
  const lowerCaseString = string.toLowerCase();
  //Regex cleaning special characters and replace space with dash
  return lowerCaseString.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ +/g, "-");
};

export {
  formatPrice,
  getVatAmount,
  getIntervalText,
  getIntervalTexts,
  slugging,
};
