import React, { Component } from "react";
import { TOKEN } from "../constants";
import { withStyles } from "@material-ui/core/styles";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import CenteredProgress from "./CenteredProgress";
import Button from "@material-ui/core/Button";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

const PROPOSAL_QUERY = gql`
  query($token: String!, $proposalId: ID!) {
    proposal(token: $token, proposalId: $proposalId) {
      _id
      status
      title
      options
      optionIndex
    }
  }
`;

const ANSWER_PROPOSAL_MUTATION = gql`
  mutation($token: String!, $proposalId: ID!, $optionIndex: Int) {
    answerProposal(
      token: $token
      proposalId: $proposalId
      optionIndex: $optionIndex
    ) {
      _id
      status
      title
      options
      optionIndex
    }
  }
`;

const styles = theme => ({});

class Proposal extends Component {
  render() {
    const { proposalQuery, t, answerProposalMutation } = this.props;

    if (proposalQuery.loading) {
      return (
        <div
          style={{
            flex: 1,
            height: "100%",
          }}
        >
          <CenteredProgress />
        </div>
      );
    }

    const { proposal } = proposalQuery;

    return (
      <div style={{ height: "100%" }} className="proposal">
        <div className="paper" style={{ overflow: "auto" }}>
          <div className="paper-header">
            <h3 style={{ margin: 0 }}>{proposal.title}</h3>
          </div>
          <div style={{ padding: "10px 20px" }} className="paper-content">
            <ol>
              {proposal.options.map((option, index) => (
                <li
                  key={index}
                  style={{
                    fontWeight:
                      index === proposal.optionIndex ? "bold" : "normal",
                  }}
                  className={
                    (proposal.status === "open" ? "button " : "") +
                    (index === proposal.optionIndex ? "selected" : "")
                  }
                >
                  <span>{option}</span>
                  {index === proposal.optionIndex && (
                    <PhosphorIcon name="check-circle" className="icon" />
                  )}
                  {proposal.status === "open" && (
                    <Button
                      key={index}
                      color="secondary"
                      onClick={() =>
                        answerProposalMutation({
                          variables: {
                            token: localStorage.getItem(TOKEN),
                            proposalId: proposal._id,
                            optionIndex: index,
                          },
                        })
                      }
                    >
                      {t("option")}
                    </Button>
                  )}
                </li>
              ))}
            </ol>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button color="secondary" onClick={() => window.history.back()}>
                {t("back")}
              </Button>
              {proposal.status === "open" && (
                <Button
                  color="primary"
                  onClick={() =>
                    answerProposalMutation({
                      variables: {
                        token: localStorage.getItem(TOKEN),
                        proposalId: proposal._id,
                        optionIndex: null,
                      },
                    })
                  }
                >
                  {t("noneOfTheAbove")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ProposalWithGraphql = flowRight(
  graphql(PROPOSAL_QUERY, {
    name: "proposalQuery",
    options: ({ id }) => ({
      variables: { token: localStorage.getItem(TOKEN), proposalId: id },
    }),
  }),
  graphql(ANSWER_PROPOSAL_MUTATION, {
    name: "answerProposalMutation",
    options: optionArgs => {
      return {
        update: (store, { data: { answerProposal } }) => {
          store.writeQuery({
            query: PROPOSAL_QUERY,
            variables: optionArgs.proposalQuery.variables,
            data: { proposal: answerProposal },
          });
        },
      };
    },
  })
)(withStyles(styles, { withTheme: true })(Proposal));

const ProposalWithRouter = withRouter(ProposalWithGraphql);

export default translate("Proposal")(ProposalWithRouter);
