import React, { Component } from "react";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import "../spectre.min.css";

const PAYMENT_INFO_QUERY = gql`
  query($hash: String!) {
    paymentInfo(hash: $hash) {
      status
    }
  }
`;

const PAYMENT_INFO_SUBSCRIPTION = gql`
  subscription($hash: String!) {
    paymentUpdate(hash: $hash) {
      status
    }
  }
`;

class OnlineTransferPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      completePaymentError: null,
    };
  }

  componentWillMount() {
    this.props.paymentInfoQuery.subscribeToMore({
      document: PAYMENT_INFO_SUBSCRIPTION,
      variables: { hash: this.props.hash },
      updateQuery: (prev, { subscriptionData }) => {
        return {
          paymentInfo: subscriptionData.data.paymentUpdate,
        };
      },
    });
  }

  render() {
    return (
      <div className="container grid-xl payment-page">
        <div className="columns">
          <div className="column col-12 col-mx-auto logo">
            <img style={{ width: 200 }} src="/ubutler-logo.svg" alt="uButler" />
          </div>
        </div>
        <div className="columns">
          <div className="column col-mx-auto col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-6">
            <div className="paper">
              <div>
                {this.props.paymentInfoQuery.loading ? (
                  <div className="server-message">
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    {this.props.paymentInfoQuery.paymentInfo.status ===
                      "active" && (
                      <div className="server-message">
                        <h3>Your payment is being proccessed.</h3>
                      </div>
                    )}
                    {this.props.paymentInfoQuery.paymentInfo.status ===
                      "unknown" && (
                      <div className="server-message failed">
                        <h3>Your order was not found.</h3>
                      </div>
                    )}
                    {this.props.paymentInfoQuery.paymentInfo.status ===
                      "failed" && (
                      <div className="server-message failed">
                        <h3>Your payment failed.</h3>
                      </div>
                    )}
                    {this.props.paymentInfoQuery.paymentInfo.status ===
                      "fulfilled" && (
                      <div className="server-message success">
                        <h3>Your order is paid.</h3>
                      </div>
                    )}
                    {this.props.paymentInfoQuery.paymentInfo.status ===
                      "expired" && (
                      <div className="server-message">
                        <h3>Your order is expired.</h3>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const OnlineTransferGraphQL = flowRight(
  graphql(PAYMENT_INFO_QUERY, {
    name: "paymentInfoQuery",
    options: ({ hash }) => ({
      variables: { hash: hash },
    }),
  })
)(withRouter(OnlineTransferPayment));

export default OnlineTransferGraphQL;
