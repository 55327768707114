import React from "react";

const PhosphorIcon = ({
  name,
  size = 24,
  style = {},
  className = "",
  onClick,
  ...props
}) => {
  const src = `/phosphor-icon-files/${name}.svg`;

  return (
    <img
      src={src}
      width={size}
      height={size}
      style={{ ...style }}
      className={className}
      alt={props.alt ?? name}
      onClick={onClick}
      {...props}
    />
  );
};

export default PhosphorIcon;
