import i18n from "i18next";
import translations from "./translations";

const languages = Object.keys(translations);
languages.forEach(lang1 => {
  languages.forEach(lang2 => {
    const trans1 = translations[lang1];
    const trans2 = translations[lang2];

    Object.keys(trans1).forEach(cat => {
      const obj = trans1[cat];
      if (!trans2[cat]) {
        console.log("translation category", cat, "missing in language", lang2);
      } else {
        Object.keys(obj).forEach(key => {
          if (!trans2[cat][key]) {
            console.log(
              "translation",
              cat + "." + key,
              "missing in language",
              lang2
            );
          }
        });
      }
    });
  });
});

i18n.init({
  fallbackLng: "en",
  // the translations
  // realworld load that via xhr or bundle those using webpack
  resources: {
    ...translations,
    ns: ["translation"],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // not needed for react
    },
  },
});

export default i18n;
