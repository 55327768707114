import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import styled from "styled-components/macro";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from "react-router-dom";

import { getToken } from "../../constants";
import { TopicsOverviewPage } from "./TopicsOverviewPage";
import { TopicPage } from "./TopicPage";
import { TodoPage } from "./TodoPage";
import { QAndAPage } from "./QAndAPage";

import { ScrollToTop } from "./Components";
import { slugging } from "../../helpers";

export const TOPICS_QUERY = gql`
  query($token: String!) {
    me(token: $token) {
      _id
      features {
        chat
        knowledgebase
      }
      company {
        _id
        name
        branding {
          brandColor
          invertedTextColor
        }
      }
      topics {
        _id
        icon
        title
        category
        content {
          _id
          type
          body
          url
          videoId
        }
        questionsAndAnswers {
          _id
          question
          answer
          createdAt
        }
        todos {
          _id
          title
          content {
            _id
            type
            body
            url
            videoId
          }
          policy {
            title
            description
          }
          handle
          handleInstructions
          description
          requirements {
            title
          }
          questionsAndAnswers {
            _id
            question
            answer
          }
          steps {
            title
          }
        }
      }
    }
  }
`;

export const KnowledgeBase = () => {
  let match = useRouteMatch();
  const history = useHistory();

  const { data, loading } = useQuery(TOPICS_QUERY, {
    variables: {
      token: getToken(),
    },
  });

  if (loading) {
    return <div style={{ margin: "auto", textAlign: "center" }}>Loading</div>;
  }

  const knowledgebaseFlag = data.me.features.knowledgebase;
  if (!knowledgebaseFlag) {
    return <Redirect to="/" />;
  }

  const companyBranding = data.me.company.branding || {
    brandColor: "#42c175",
    invertedTextColor: "white",
  };

  const {
    me: { company, features, topics },
  } = data;
  let knowledgebase_path = match.path;
  let knowledgebase_url = match.path;

  return (
    <KBWrapper>
      <ScrollToTop />

      <Switch>
        <Route
          exact
          path={[
            `${knowledgebase_path}/questions/:slugQuestion`,
            `${knowledgebase_path}/:slugTopicTitle/questions/:slugQuestion`,
          ]}
        >
          <QAndAPage />
        </Route>
        <Route
          exact
          path={`${knowledgebase_path}/:slugTopicTitle/:slugTodoTitle`}
          render={props => {
            let { slugTopicTitle, slugTodoTitle } = props.match.params;
            const topic = topics.find(
              topic => slugging(topic.title) === slugTopicTitle
            );
            if (topic == null) {
              return <Redirect to={knowledgebase_url} />;
            }
            const todo = topic.todos.find(
              todo => slugging(todo.title) === slugTodoTitle
            );
            if (todo == null) {
              return <Redirect to={`${knowledgebase_url}/${slugTopicTitle}`} />;
            }
            return (
              <TodoPage
                onGoBack={() => {
                  history.push(`${knowledgebase_url}/${slugTopicTitle}`);
                }}
                topic={topic}
                todo={todo}
                companyBranding={companyBranding}
                chatEnabled={features.chat}
              />
            );
          }}
        />
        <Route
          exact
          path={`${knowledgebase_path}/:slugTopicTitle`}
          render={props => {
            let { slugTopicTitle } = props.match.params;
            const topic = topics.find(
              topic => slugging(topic.title) === slugTopicTitle
            );
            if (topic == null) {
              return <Redirect to={knowledgebase_url} />;
            }
            return (
              <TopicPage
                onGoBack={() => {
                  history.push(`${knowledgebase_url}`);
                }}
                topic={topic}
                companyBranding={companyBranding}
              />
            );
          }}
        />
        <Route exact path={knowledgebase_path}>
          <TopicsOverviewPage topics={topics} company={company} />
        </Route>
      </Switch>
    </KBWrapper>
  );
};

const KBWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100%;
`;
