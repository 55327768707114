import React from "react";
import styled from "styled-components/macro";
import dompurify from "dompurify";
import { useHistory } from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { GoBack } from "./TopicPage";
import { Container } from "./Components.js";

export const QAndAPage = () => {
  const history = useHistory();
  const qa = history.location.state;

  return (
    <QALayout>
      <Container>
        <GoBack>
          <ArrowBack onClick={() => history.goBack()} fontSize="large" />
        </GoBack>
        <QuestionTitle>{qa.question}</QuestionTitle>
        <Answer
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(qa.answer) }}
        />
      </Container>
    </QALayout>
  );
};

const QALayout = styled.div`
  padding: 18px;
  background-color: #ffffff;
  flex: 1;
`;

const QuestionTitle = styled.h1`
  font-family: "Muli", sans-serif;
  font-size: 24px;
  font-weight: 800;
  color: #072533;
`;

const Answer = styled.section`
  color: #2a2135;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.75;
`;
