import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { slugging } from "../helpers";

export const Questions = ({ questions }) => {
  const history = useHistory();

  return (
    <QAWrapper>
      <QATitle>Questions & Answers</QATitle>
      <QuestionsWrapper>
        {questions.length > 0 &&
          questions.map((qa, index) => {
            return (
              <SingleQuestion
                key={qa._id}
                onClick={() => {
                  return history.push(`questions/${slugging(qa.question)}`, qa);
                }}
                style={{
                  borderTopLeftRadius: index === 0 ? "5px" : "none",
                  borderTopRightRadius: index === 0 ? "5px" : "none",
                  borderBottomLeftRadius:
                    index === questions.length - 1 ? "5px" : "none",
                  borderBottomRightRadius:
                    index === questions.length - 1 ? "5px" : "none",
                }}
              >
                {qa.question}
              </SingleQuestion>
            );
          })}
      </QuestionsWrapper>
    </QAWrapper>
  );
};

const QAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
`;

const QATitle = styled.h1`
  font-size: 16px;
  font-weight: 800;
`;

const QuestionsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SingleQuestion = styled.div`
  overflow: hidden;
  padding: 15px;
  margin: 1px 0px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: #072533;
  background-color: #ffffff;

  &:hover {
    cursor: pointer;
    background-color: #e1e5e7;
  }
`;
