import React from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { slugging } from "../../helpers";
import { TodoButton } from "../../components/TodoButton";
import { Content } from "../../components/Content";
import { Questions } from "../../components/Questions";
import { Icon } from "../../components/Icon/Icon";
import { Container } from "./Components.js";

export const TopicPage = ({ onGoBack, topic, companyBranding }) => {
  let match = useRouteMatch();
  const { content, todos, icon, title, questionsAndAnswers } = topic;

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#eef2f5" }}>
      <TopicHeader>
        <Container style={{ padding: 18, textAlign: "center" }}>
          <GoBack>
            <ArrowBack onClick={() => onGoBack()} fontSize="large" />
          </GoBack>
          <Icon
            name={icon}
            color={companyBranding.brandColor}
            style={{ fontSize: 60 }}
          />
          <TopicHeaderTitle style={{ color: companyBranding.brandColor }}>
            {title}
          </TopicHeaderTitle>
          <NumberOfTodos>
            {todos.length} service{todos.length === 1 ? "" : "s"}
          </NumberOfTodos>
          <ul>
            {todos.map(todo => {
              return (
                <li key={todo._id}>
                  <TodoButton
                    policy={todo.policy}
                    title={todo.title}
                    companyBranding={companyBranding}
                    url={`${match.url}/${slugging(todo.title)}`}
                  />
                </li>
              );
            })}
          </ul>
        </Container>
      </TopicHeader>

      <div style={{ backgroundColor: "white", margin: "16px 0" }}>
        <Container style={{ padding: 18, fontSize: 16 }}>
          {content && <Content content={content} title="General information" />}
        </Container>
      </div>

      {questionsAndAnswers.length > 0 && (
        <Container>
          <Questions questions={questionsAndAnswers} />
        </Container>
      )}
    </div>
  );
};

export const GoBack = styled.div`
  text-align: left;
  color: #657786;
  > :hover {
    cursor: pointer;
    color: black;
  }
`;

const TopicHeader = styled.header`
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    margin: 16px 0px;
    padding: 0;
  }
`;

const TopicHeaderTitle = styled.h1`
  font-family: "Muli", sans-serif;
  font-size: 37px;
  font-weight: 800;
  margin: 18px 0px;
`;

const NumberOfTodos = styled.p`
  font-family: "Muli", sans-serif;
  opacity: 0.75;
  font-size: 16px;
  font-weight: bold;
  color: #657786;
  margin: 0;
`;
