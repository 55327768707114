import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { getToken } from "../../constants";
import { LongButton } from "../../components/LongButton";
import { Content, HTMLContent } from "../../components/Content";
import { GoBack } from "./TopicPage";
import { Questions } from "../../components/Questions";

import { Container, Center } from "./Components.js";

let ASK_SOPHIE_MUTATION = gql`
  mutation AskSophieMutation(
    $token: String!
    $todoId: ID!
    $companyPolicy: InputTodoPolicy
  ) {
    ask_sophie(token: $token, todo_id: $todoId, companyPolicy: $companyPolicy)
  }
`;

export const TodoPage = ({
  chatEnabled,
  companyBranding,
  onGoBack,
  todo,
  topic,
}) => {
  const [showReadMore, setShowReadMore] = useState(false);
  const history = useHistory();

  let [ask_sophie] = useMutation(ASK_SOPHIE_MUTATION);

  const {
    title,
    handle,
    handleInstructions,
    description,
    requirements,
    steps,
    policy,
    content,
    questionsAndAnswers,
  } = todo;

  return (
    <div style={{ width: "100%", backgroundColor: "#eef2f5" }}>
      <Container>
        <TodoHeader>
          <GoBack>
            <ArrowBack onClick={() => onGoBack()} fontSize="large" />
          </GoBack>
          <TopicTitle>
            Topic: <br />
            {topic.title}
          </TopicTitle>
          <TodoTitle>{title}</TodoTitle>

          <BadgesWrapper>
            <ChatHandleBadge
              style={{
                color: companyBranding.brandColor,
                borderColor: companyBranding.brandColor,
              }}
            >
              <CheckIcon style={{ fontSize: 14, margin: 3 }} />
              <div>Chatting</div>
            </ChatHandleBadge>

            {handle && (
              <ChatHandleBadge
                style={{
                  color: companyBranding.brandColor,
                  borderColor: companyBranding.brandColor,
                }}
              >
                <CheckIcon style={{ fontSize: 14, margin: 3 }} />
                <div>Handling</div>
              </ChatHandleBadge>
            )}
            {!handle && (
              <ChatHandleBadge
                style={{
                  color: "#c5d2db",
                  borderColor: `#c5d2db`,
                }}
              >
                <CloseIcon style={{ fontSize: 14, margin: 3 }} />
                <div>Handling</div>
              </ChatHandleBadge>
            )}
          </BadgesWrapper>

          {handle && (
            <LongButton
              backgroundColor={companyBranding.brandColor}
              textColor={companyBranding.invertedTextColor}
              backgroundHoverColor={companyBranding.brandColor}
              textHoverColor={companyBranding.invertedTextColor}
              title="Let Sophie handle this service"
              disabled={chatEnabled !== true}
              onClick={async () => {
                await ask_sophie({
                  variables: {
                    todoId: todo._id,
                    token: getToken(),
                    companyPolicy: policy
                      ? {
                          title: policy.title,
                          description: policy.description,
                        }
                      : null,
                  },
                });
                history.push(`/`);
              }}
            />
          )}
          {!handle && handleInstructions && (
            <NotHandlingMessage>{handleInstructions}</NotHandlingMessage>
          )}
        </TodoHeader>

        <SectionWrapper>
          <SectionTitle>Quick services overview</SectionTitle>
          <SectionDetails>
            <HTMLContent body={description} />

            {requirements.length > 0 && (
              <div>
                <SubSectionTitle>Requirements</SubSectionTitle>
                <RequirementsList>
                  {requirements.map((requirement, index) => (
                    <Requirement key={index}>{requirement.title}</Requirement>
                  ))}
                </RequirementsList>
              </div>
            )}

            {steps.length > 0 && (
              <div>
                <SubSectionTitle>Steps</SubSectionTitle>
                <StepsList>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepNumber
                        style={{
                          backgroundColor: companyBranding.brandColor,
                          color: companyBranding.invertedTextColor,
                        }}
                      >
                        {index + 1}
                      </StepNumber>
                      <StepTitle> {step.title}</StepTitle>
                    </Step>
                  ))}
                </StepsList>
              </div>
            )}
          </SectionDetails>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>Company policy</SectionTitle>
          <SectionDetails>
            <SubSectionTitle>{policy?.title || "No policy"}</SubSectionTitle>
            <span>
              {policy?.description ||
                `There are no policies from your employer within this service! Together with Sophie you go through the process and determine how you want to get things done.`}
            </span>
          </SectionDetails>
        </SectionWrapper>

        {content.length > 0 && (
          <div>
            <ReadMoreOption
              onClick={() => setShowReadMore(!showReadMore)}
              style={{ color: companyBranding.brandColor }}
            >
              <Center>Show {showReadMore ? "less" : "more"}</Center>
              <Center>
                {!showReadMore && <ExpandMore className="expand" />}
                {showReadMore && <ExpandLess className="expand" />}
              </Center>
            </ReadMoreOption>
            {showReadMore && (
              <SectionWrapper>
                <SectionDetails>
                  <Content content={content} title="More information" />
                  <ReadMoreOption
                    onClick={() => setShowReadMore(!showReadMore)}
                    style={{ color: companyBranding.brandColor }}
                  >
                    <Center>Show Less</Center>
                    {showReadMore && <ExpandLess className="expand" />}
                  </ReadMoreOption>
                </SectionDetails>
              </SectionWrapper>
            )}
          </div>
        )}

        {questionsAndAnswers.length > 0 && (
          <Questions questions={questionsAndAnswers} />
        )}
      </Container>
    </div>
  );
};

const TodoHeader = styled.section`
  justify-content: center;
  text-align: center;
  padding: 18px;
`;

const TopicTitle = styled.p`
  height: 30px;
  font-size: 12px;
  font-family: "Muli", sans-serif;
  font-weight: bold;
  line-height: 1.25;
  color: #657786;
`;

const TodoTitle = styled.h1`
  font-family: "Muli", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;
  color: #072533;
`;

const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
`;

const ChatHandleBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding-right: 5px;
  width: 83.4px;
  height: 28px;
  border-radius: 14px;
  font-family: "Muli", sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 1.3;
  border-width: 1.5px;
  border-style: solid;
`;

const SectionWrapper = styled.div`
  font-family: "Muli", sans-serif;
  padding: 18px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;
  color: #072533;
`;

const SectionDetails = styled.section`
  border-radius: 5px;
  background-color: #ffffff;
  padding: 15px 18px;
  font-size: 14px;
`;

const SubSectionTitle = styled.h3`
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.29;
  margin: 8px 0px;
  text-align: left;
  color: #072533;
`;

const StepsList = styled.ul`
  margin: 0;
`;

const Step = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const StepNumber = styled.div`
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
`;

const StepTitle = styled.div`
  margin: 0px 8px;
  font-size: 12px;
`;

const RequirementsList = styled.ul`
  list-style: none;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
`;

const Requirement = styled.li`
  margin: 0px 8px 8px 0;
  padding: 8px;
  border-radius: 5px;
  background-color: #eef2f5;
  font-family: "Muli", sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.3;
  color: #072533;

  &:last-of-type {
    margin-bottom: 6px; // Overriding stupid default.
  }
`;

const NotHandlingMessage = styled.section`
  width: 100%;
  padding: 15px;
  background: none;
  border: 1px solid #c5d2db;
  border-radius: 5px;
  text-align: left;
  outline: 0;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #8293a2;
`;

const ReadMoreOption = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  font-family: "Muli", sans-serif;
  cursor: pointer;
`;
