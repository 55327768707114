import styled from "styled-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export let ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export let Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;

  padding: 18px;
  width: 375px;

  @media only screen and (max-device-width: 480px) {
    width: 100%;
  }
`;

export let Center = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
`;
