import React from "react";
import styled from "styled-components/macro";

export const YouTube = ({ videoId }) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=0&loop=1`;

  return (
    <VideoWrapper>
      <iframe
        frameBorder="0"
        src={videoUrl}
        allowfullscreen="allowfullscreen"
      />
    </VideoWrapper>
  );
};

//Style is meant to make the video responsive. followed this article:
// https://www.smashingmagazine.com/2014/02/making-embedded-content-work-in-responsive-design/
const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 48%; /* The minimal padding without having black stripes on the top and bottom of the video*/
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
