import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import { translate } from "react-i18next";
import i18n from "i18next";
import CenteredProgress from "./CenteredProgress";

class Settings extends Component {
  state = {
    disabled: false,
  };

  render() {
    const { t } = this.props;

    if (!this.props.client) {
      return <CenteredProgress />;
    }
    return (
      <List
        style={{
          maxWidth: 1000,
          boxSizing: "border-box",
        }}
      >
        <div className="paper">
          <div className="paper-header">
            <h3 style={{ margin: 0 }}>{t("webSessionHoursMax")}</h3>
          </div>
          <div style={{ padding: "10px 20px" }}>
            <FormControl component="fieldset" disabled={this.state.disabled}>
              <p style={{ margin: 0 }}>{t("webSessionHoursMaxExplanation")}</p>
              <RadioGroup
                value={this.props.client.webSessionHoursMax.toString()}
                onChange={(ev, value) =>
                  this.handleChange({ webSessionHoursMax: parseInt(value, 10) })
                }
                style={{ flexDirection: "column" }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("1hour")}
                />
                <FormControlLabel
                  value="24"
                  control={<Radio />}
                  label={t("1day")}
                />
                <FormControlLabel
                  value="168"
                  control={<Radio />}
                  label={t("1week")}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className="paper">
          <div className="paper-header">
            <h3 style={{ margin: 0 }}>{t("daily")}</h3>
          </div>
          <div style={{ padding: "10px 20px" }}>
            <FormControl component="fieldset" disabled={this.state.disabled}>
              <p style={{ margin: 0 }}>{t("dailyExplanation")}</p>
              <div
                style={{
                  display: "flex",
                  flexDirecion: "row",
                  flexWrap: "wrap",
                }}
              >
                <FormControlLabel
                  value="auto"
                  control={
                    <Switch
                      checked={this.props.client.sendDaily}
                      onChange={(ev, value) =>
                        this.handleChange({ sendDaily: value })
                      }
                    />
                  }
                  label={t("sendAt")}
                />
                <Input
                  style={{ marginBottom: 16 }}
                  type="time"
                  disabled={!this.props.client.sendDaily || this.state.disabled}
                  defaultValue={
                    ("0" + this.props.client.dailyHour).slice(-2) +
                    ":" +
                    ("0" + this.props.client.dailyMinute).slice(-2)
                  }
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onBlur={e => {
                    const parts = e.target.value.split(":");
                    this.handleChange({
                      dailyHour: parseInt(parts[0], 10),
                      dailyMinute: parseInt(parts[1], 10),
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <FormControlLabel
                  value="auto"
                  control={
                    <Switch
                      checked={this.props.client.dailyAppointments}
                      onChange={(ev, value) =>
                        this.handleChange({ dailyAppointments: value })
                      }
                      disabled={
                        !this.props.client.sendDaily || this.state.disabled
                      }
                    />
                  }
                  label={t("appointments")}
                />

                <FormControlLabel
                  value="auto"
                  control={
                    <Switch
                      checked={this.props.client.dailyOrders}
                      onChange={(ev, value) =>
                        this.handleChange({ dailyOrders: value })
                      }
                      disabled={
                        !this.props.client.sendDaily || this.state.disabled
                      }
                    />
                  }
                  label={t("requests")}
                />

                <FormControlLabel
                  value="auto"
                  control={
                    <Switch
                      checked={this.props.client.dailyWeather}
                      onChange={(ev, value) =>
                        this.handleChange({ dailyWeather: value })
                      }
                      disabled={
                        !this.props.client.sendDaily || this.state.disabled
                      }
                    />
                  }
                  label={t("weather")}
                />

                <FormControlLabel
                  value="auto"
                  control={
                    <Switch
                      checked={this.props.client.dailyNews}
                      onChange={(ev, value) =>
                        this.handleChange({ dailyNews: value })
                      }
                      disabled={
                        !this.props.client.sendDaily || this.state.disabled
                      }
                    />
                  }
                  label={t("news")}
                />
              </div>
            </FormControl>
          </div>
        </div>
      </List>
    );
  }

  async handleChange(key, value) {
    //this.setState({ disabled: true });
    await this.props.onUpdateSetting(key, value);
    //this.setState({ disabled: false });
  }
}

export default translate("Settings")(Settings);
