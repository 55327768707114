import Paper from "@material-ui/core/Paper";
import React from "react";
import { Background } from "../components/styled/Background";

export class PaymentConclusionPage extends React.Component {
  getTexts = () => {
    switch (this.props.conclusion) {
      case "payment-success":
        return {
          title: "Success!",
          body: "The payment was successful.",
        };
      case "payment-failed":
        return {
          title: "Payment failed",
          body:
            "The payment did not succeed. Contact Sophie if this keeps happening.",
        };
      case "payment-cancelled":
        return {
          title: "Payment cancelled",
          body: "You cancelled the payment. We will not charge your card.",
        };
      case "card-add-success":
        return {
          title: "Card added!",
          body:
            "Your new credit card was added to your account. If you were in the process of paying for an order, we will try to charge this new card for you.",
        };
      case "card-add-cancelled":
        return {
          title: "Card not added",
          body:
            "You cancelled the process to add a new credit card. You can click the link in the chat to restart the process.",
        };
      default:
        return {
          title: "Oops",
          body:
            "Something seems to have gone wrong. Contact Sophie if you need further assistance.",
        };
    }
  };

  render() {
    const { title, body } = this.getTexts();

    return (
      <Background>
        <Paper style={{ width: "400px", padding: "16px 20px 12px" }}>
          <h2 style={{ margin: 0 }}>{title}</h2>

          <p
            style={{ fontSize: "16px", lineHeight: 1.5, margin: "20px 0 10px" }}
          >
            {body} You can close this window and return to the chat.
          </p>
        </Paper>
      </Background>
    );
  }
}
