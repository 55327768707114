import fileDialog from "file-dialog";
import { flowRight } from "lodash";
import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { translate } from "react-i18next";
import * as uuid from "uuid";
import { TOKEN } from "../constants";
import { ChatWrapper } from "./ChatWrapper";

class MainChatComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstUnreadMsgId: null,
    };
    this.subscription = null;
  }

  componentWillMount() {
    const { t } = this.props;

    if (!this.subscription) {
      this.subscription = [
        //Subscribe to update messages
        this.props.messagesQuery.subscribeToMore({
          document: MESSAGES_ADD_SUBSCRIPTION,
          variables: {
            token: localStorage.getItem(TOKEN),
          },
          updateQuery: (prev, { subscriptionData }) => {

            if (!subscriptionData.data) return prev;

            let found = false;
            let newList = prev.messages.map(rec => {
              //check if message id exist in previous. if so replace with new
              if (
                rec._id === subscriptionData.data.messageUpdate._id ||
                (rec.externalId &&
                  subscriptionData.data.messageUpdate.externalId &&
                  rec.externalId ===
                    subscriptionData.data.messageUpdate.externalId)
              ) {
                found = true;

                return subscriptionData.data.messageUpdate;
              } else {
                return rec;
              }
            });

            //If new message doesn't exist in previous, add to newList
            if (!found) {
              if (!subscriptionData.data.messageUpdate.sentByUser) {
                if (
                  !document.hasFocus() &&
                  !subscriptionData.data.messageUpdate.sentByUser
                ) {
                  if (!this.state.firstUnreadMsgId) {
                    this.setState({
                      firstUnreadMsgId: subscriptionData.data.messageUpdate._id,
                    });
                  }

                  document.title = "* " + this.props.pageTitle;

                  this.props.onNewMessage(this.state.unreadMessagesAmount);

                  if (
                    window.Notification &&
                    window.Notification.permission === "granted"
                  ) {
                    const notification = new window.Notification(
                      t("newMessage"),
                      {
                        badge: "/favicon.png",
                        body: subscriptionData.data.messageUpdate.content,
                        lang: "nl-NL",
                        icon: "/favicon.png",
                        vibrate: 200,
                      }
                    );
                    notification.onclick = e => window.focus();
                    notification.addEventListener("click", e => window.focus());
                  }
                } else {
                  this.setState({
                    firstUnreadMsgId: null,
                  });
                }
              }
              newList.push(subscriptionData.data.messageUpdate);
            }

            return {
              messages: newList,
            };
          },
        }),
        //Subscribe to delete messages
        this.props.messagesQuery.subscribeToMore({
          document: MESSAGE_DELETE_SUBSCRIPTION,
          variables: {
            token: localStorage.getItem(TOKEN),
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            this.setState({
              firstUnreadMsgId: null,
            });

            return {
              messages: prev.messages.filter(
                message =>
                  message._id !== subscriptionData.data.messageDelete._id
              ),
            };
          },
        }),
      ];
    }
  }

  render() {
    return (
      <ChatWrapper
        title="Chat with Sophie"
        messages={this.props.messagesQuery.messages}
        loadingMessages={this.props.messagesQuery.loading}
        loadMoreMessages={this.props.loadMoreMessages}
        firstUnreadMsgId={this.state.firstUnreadMsgId}
        setMobileOpen={this.props.setMobileOpen}
        locale={this.props.locale}
        onSubmitMessage={async arg => {
          this.setState({
            firstUnreadMsgId: null,
          });
          const externalId = uuid.v4();
          await this.props.sendMessageMutation({
            variables: {
              ...arg,
              externalId,
              token: localStorage.getItem(TOKEN),
            },

            optimisticResponse: {
              sendMessage: {
                __typename: "Message",
                _id: externalId,
                externalId: externalId,
                sentByUser: true,
                createdAt: new Date(),
                content: arg.content,
                options: null,
                type: "text",
                latitude: null,
                longitude: null,
                url: null,
                status: "sending",
              },
            },
          });
        }}
        onSendImage={async () => {
          this.setState({
            firstUnreadMsgId: null,
          });
          const file = await fileDialog({
            accept: "image/*",
          });
          const externalId = uuid.v4();

          const retval = await this.props.sendImageFileMutation({
            variables: {
              externalId,
            },
            optimisticResponse: {
              sendImageFile: {
                __typename: "SendImageFileResponse",
                signedQuery: null,
                url: null,
                message: {
                  __typename: "Message",
                  _id: externalId,
                  externalId: externalId,
                  sentByUser: true,
                  createdAt: new Date(),
                  content: null,
                  options: null,
                  type: "image",
                  latitude: null,
                  longitude: null,
                  url: null,
                  status: "sending",
                },
              },
            },
          });
          const urlInfo = retval.data.sendImageFile;

          fetch(`${urlInfo.url}?${urlInfo.signedQuery}`, {
            method: "PUT",
            body: file[0],
            headers: new Headers({
              "Content-Type": "application/octet-stream",
            }),
          });

          await this.props.confirmImageFileMutation({
            variables: {
              id: urlInfo.message._id,
            },
          });
        }}
        onSendLocation={() => {
          navigator.geolocation.getCurrentPosition(
            async pos => {
              try {
                this.setState({
                  firstUnreadMsgId: null,
                });

                const externalId = uuid.v4();

                await this.props.sendLocationMutation({
                  variables: {
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    externalId,
                    token: localStorage.getItem(TOKEN),
                  },
                  optimisticResponse: {
                    sendLocation: {
                      __typename: "Message",
                      _id: externalId,
                      externalId: externalId,
                      sentByUser: true,
                      createdAt: new Date(),
                      content: null,
                      options: null,
                      type: "location",
                      latitude: pos.coords.latitude,
                      longitude: pos.coords.longitude,
                      url: null,
                      status: "sending",
                    },
                  },
                });
              } catch (e) {
                this.props.onLocationError();
              }
            },
            async err => {
              this.props.onLocationError();
            }
          );
        }}
      />
    );
  }
}

export const MESSAGES_QUERY = gql`
  query($token: String!, $amount: Int!, $before: Date) {
    messages(
      token: $token
      amount: $amount
      before: $before
      resolveImgUrl: true
    ) {
      _id
      sentByUser
      createdAt
      content
      options
      type
      latitude
      longitude
      url
      status
      externalId
    }
  }
`;

const MESSAGES_ADD_SUBSCRIPTION = gql`
  subscription($token: String!) {
    messageUpdate(token: $token) {
      _id
      sentByUser
      createdAt
      content
      options
      type
      latitude
      longitude
      url
      status
      externalId
    }
  }
`;

const MESSAGE_DELETE_SUBSCRIPTION = gql`
  subscription($token: String!) {
    messageDelete(token: $token) {
      _id
      clientId
    }
  }
`;

const SEND_MESSAGE_MUTATION = gql`
  mutation($token: String!, $content: String!, $externalId: String!) {
    sendMessage(token: $token, content: $content, externalId: $externalId) {
      _id
      sentByUser
      createdAt
      content
      options
      type
      latitude
      longitude
      url
      status
      externalId
    }
  }
`;

const SEND_IMAGE_FILE_MUTATION = gql`
  mutation($token: String!, $externalId: String!) {
    sendImageFile(token: $token, externalId: $externalId) {
      url
      signedQuery
      message {
        _id
        sentByUser
        createdAt
        content
        options
        type
        latitude
        longitude
        url
        status
        externalId
      }
    }
  }
`;

const CONFIRM_IMAGE_FILE_MUTATION = gql`
  mutation($token: String!, $id: String!) {
    confirmImageFile(token: $token, _id: $id) {
      _id
      sentByUser
      createdAt
      content
      options
      type
      latitude
      longitude
      url
      status
    }
  }
`;

const SEND_LOCATION_MUTATION = gql`
  mutation(
    $token: String!
    $latitude: Float!
    $longitude: Float!
    $externalId: String!
  ) {
    sendLocation(
      token: $token
      latitude: $latitude
      longitude: $longitude
      externalId: $externalId
    ) {
      _id
      sentByUser
      createdAt
      content
      options
      type
      latitude
      longitude
      url
      status
      externalId
    }
  }
`;

const messagesQueryVariables = () => ({
  token: localStorage.getItem(TOKEN),
  amount: 40,
});

export const MainChat = flowRight(
  translate("MainApp"),
  graphql(MESSAGES_QUERY, {
    name: "messagesQuery",
    options: () => ({
      variables: messagesQueryVariables(),
    }),
    props: args => {
      return {
        messagesQuery: args.messagesQuery,
        loadMoreMessages: (before, compThis) => {
          return args.messagesQuery.fetchMore({
            variables: {
              before,
              amount: 3,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }

              compThis.setState({ loadingMore: false });

              return {
                messages: [
                  ...fetchMoreResult.messages,
                  ...previousResult.messages,
                ],
              };
            },
          });
        },
      };
    },
  }),
  graphql(SEND_MESSAGE_MUTATION, {
    name: "sendMessageMutation",
    options: args => {
      return {
        update: (proxy, { data: { sendMessage } }) => {
          const data = proxy.readQuery({
            query: MESSAGES_QUERY,
            variables: messagesQueryVariables(),
          });

          let found = false;

          const newList = data.messages.map((msg, index) => {
            if (
              msg.externalId &&
              sendMessage.externalId &&
              msg.externalId === sendMessage.externalId
            ) {
              found = true;
              return sendMessage;
            } else {
              return msg;
            }
          });

          if (!found) {
            newList.push(sendMessage);
          }
          data.messages = newList;

          proxy.writeQuery({
            query: MESSAGES_QUERY,
            variables: messagesQueryVariables(),
            data,
          });
        },
      };
    },
  }),
  graphql(SEND_IMAGE_FILE_MUTATION, {
    name: "sendImageFileMutation",
    options: () => ({
      variables: { token: localStorage.getItem(TOKEN) },
      update: (proxy, { data: { sendImageFile } }) => {
        const data = proxy.readQuery({
          query: MESSAGES_QUERY,
          variables: messagesQueryVariables(),
        });

        let found = false;

        const newList = data.messages.map((msg, index) => {
          if (
            msg.externalId &&
            sendImageFile.message.externalId &&
            msg.externalId === sendImageFile.message.externalId
          ) {
            found = true;
            return sendImageFile.message;
          } else {
            return msg;
          }
        });
        if (!found) {
          newList.push(sendImageFile.message);
        }
        data.messages = newList;

        proxy.writeQuery({
          query: MESSAGES_QUERY,
          variables: messagesQueryVariables(),
          data,
        });
      },
    }),
  }),
  graphql(CONFIRM_IMAGE_FILE_MUTATION, {
    name: "confirmImageFileMutation",
    options: () => ({
      variables: { token: localStorage.getItem(TOKEN) },
    }),
  }),
  graphql(SEND_LOCATION_MUTATION, {
    name: "sendLocationMutation",
    options: ({ status }) => ({
      variables: { status, token: localStorage.getItem(TOKEN) },
      update: (proxy, { data: { sendLocation } }) => {
        const data = proxy.readQuery({
          query: MESSAGES_QUERY,
          variables: messagesQueryVariables(),
        });

        let found = false;

        const newList = data.messages.map((msg, index) => {
          if (
            msg.externalId &&
            sendLocation.externalId &&
            msg.externalId === sendLocation.externalId
          ) {
            found = true;
            return sendLocation;
          } else {
            return msg;
          }
        });
        if (!found) {
          newList.push(sendLocation);
        }
        data.messages = newList;

        proxy.writeQuery({
          query: MESSAGES_QUERY,
          variables: messagesQueryVariables(),
          data,
        });
      },
    }),
  })
)(MainChatComponent);
