import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import { TOKEN } from "../constants";
import Chat from "./Chat";
import moment from "moment";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";

export const CLIENT_QUERY = gql`
  query($token: String!) {
    client(token: $token) {
      _id
      features {
        chat
      }
      paidUntil
      serviceStartDate
    }
  }
`;

const ChatWrapperComponent = props => {
  const [setLoading] = useState(false);

  const { data: clientData } = useQuery(CLIENT_QUERY, {
    variables: {
      token: localStorage.getItem(TOKEN),
    },
  });

  const client = clientData?.client;
  let serviceEndDate = client?.paidUntil;
  let serviceStartDate = client?.serviceStartDate;
  const isAskSophieEnabled = client?.features?.chat;

  let serviceMessage;

  serviceEndDate = moment(serviceEndDate).format("DD/MM/YYYY");
  serviceStartDate = moment(serviceStartDate).format("DD/MM/YYYY");

  if (
    !isAskSophieEnabled &&
    moment().isBefore(moment(serviceStartDate, "DD.MM.YYYY HH:mm"))
  ) {
    serviceMessage = `Chat disabled, service starts on ${serviceStartDate}.`;
  } else if (
    !isAskSophieEnabled &&
    moment().isAfter(moment(serviceEndDate, "DD.MM.YYYY HH:mm"))
  ) {
    serviceMessage = `Chat disabled, service ended on ${serviceEndDate}.`;
  } else if (!isAskSophieEnabled) {
    serviceMessage = "Chat disabled, contact your relocation coordinator.";
  }

  return (
    <ChatWrapperMain>
      <section className="chat-header">
        <div className="chat-title">
          <ChatBubbleOutlineOutlinedIcon
            style={{
              color: "var(--groen)",
              marginRight: "5px",
              height: "20px",
            }}
          />
          <h3>{props.title}</h3>
        </div>
      </section>
      <Chat
        locale={props.locale}
        onSetLoadingState={loading => setLoading(loading)}
        messages={props.messages}
        loadingMessages={props.loadingMessages}
        loadMoreMessages={props.loadMoreMessages}
        onSubmitMessage={props.onSubmitMessage}
        firstUnreadMsgId={props.firstUnreadMsgId}
        onSendLocation={props.onSendLocation}
        onSendImage={props.onSendImage}
        allowInput={Boolean(
          isAskSophieEnabled && typeof props.allowInput === "undefined"
        )}
        chatHeader={props.chatHeader}
        serviceMessage={serviceMessage}
      />
    </ChatWrapperMain>
  );
};

export const ChatWrapper = ChatWrapperComponent;

let ChatWrapperMain = styled.main`
  height: 100%;
  min-width: 200px;

  .chat-header {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #d9e4eb;
    height: 56px;
  }

  & .chat-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  & > div {
    height: calc(100% - 57px); /* total height - chat-header */
    border-radius: 0px 0px 8px 8px;
  }
`;
