import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class CenteredProgress extends Component {
  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...this.props.style,
        }}
      >
        <div
          style={{
            height: 60,
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }
}
