import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import BrowserWarning from "./pages/BrowserWarning";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./registerServiceWorker";
import { detect } from "detect-browser";

const is_compatible_check = () => {
  const compatibleVersions = {
    chrome: 49,
    edge: 17,
    "edge-chromium": 79, // edge chromium is only one year old hence I've left edge in here also.
    firefox: 52,
    opera: 63,
    safari: 5.1,
    ios: 9.3,
    samsung: 4,
    android: 68,
  };
  const browser = detect();

  if (!browser || !browser.name || !browser.version) return false;
  const { name, version } = browser;

  if (!compatibleVersions[name]) return false;

  if (parseFloat(version) < compatibleVersions[name]) return false;

  return true;
};

if (is_compatible_check()) {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<BrowserWarning />, document.getElementById("root"));
}

unregister();
