import React from "react";
import logoImage from "../assets/images/ubutler-logo.svg";
import styled from "styled-components";
import appStoreIcon from "../assets/images/appstore.svg";
import googlePlayIcon from "../assets/images/google-play-store.svg";

const AppButton = styled.a`
  background: url(${({ os }) => (os === "ios" ? appStoreIcon : googlePlayIcon)})
    center no-repeat;
  background-size: auto 40px;
  height: 40px;
  margin: 10px;
  width: 150px;
`;

const BrowserButton = styled.a`
  align-items: center;
  border: 2px solid #42c175;
  border-radius: 30px;
  color: #42c175
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 10px;
  padding: 0 15px;
  text-decoration: none;
  width: 120px;

  &:hover {
    background-color: #42c175;
    color: #ffffff;
  }

  &:active {
    background-color: #42c175;
    color: #ffffff;
  }
`;

const BrowserName = styled.p`
  flex: 1;
  padding-left: 8px;
`;

const CompatibilitySubtitle = styled.p`
  font-size: 1.3rem;
  margin: auto;
  text-align: center;
  width: 80%;

  @media (min-width: 700px) {
    max-width: 700px;
  }
`;

const CompatibilityTitle = styled.h1`
  text-align: center;
`;

const CompatibilityInstruction = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 70px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 80%;
`;

const UbutlerLogo = styled.img`
  display: block;
  margin: auto;
  padding: 50px;
  width: 15rem;
`;

const BrowserWarning = () => {
  return (
    <>
      <UbutlerLogo src={logoImage} alt="uButler" />
      <CompatibilityTitle>Unsupported Browser</CompatibilityTitle>
      <CompatibilitySubtitle>
        Your browser is out of date, update to view this site correctly.
        Alternatively, download the iOS or Android application.{" "}
      </CompatibilitySubtitle>

      <CompatibilitySubtitle style={{ marginTop: "10px" }}>
        Unfortunately, we don't support Internet Explorer any more.
        <br />
        <a
          alt="say-farewell-to-internet-explorer"
          href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
        >
          More information
        </a>
        <br />
        If you are using Internet Explorer, please switch to another browser
      </CompatibilitySubtitle>
      <CompatibilityInstruction>
        Download uButler application
      </CompatibilityInstruction>
      <Container>
        <AppButton
          os="ios"
          href="https://apps.apple.com/app/ubutler/id1120195314"
          target="_blank"
          rel="noopener"
        ></AppButton>
        <AppButton
          os="android"
          href="https://play.google.com/store/apps/details?id=nl.ubutler"
          target="_blank"
          rel="noopener"
        ></AppButton>
      </Container>
      <CompatibilityInstruction>
        Download latest browser
      </CompatibilityInstruction>
      <Container>
        <BrowserButton
          browser="chrome"
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener"
        >
          <BrowserName>Chrome</BrowserName>
        </BrowserButton>
        <BrowserButton
          browser="edge"
          href="https://www.microsoft.com/edge/"
          target="_blank"
          rel="noopener"
        >
          <BrowserName>Edge</BrowserName>
        </BrowserButton>
        <BrowserButton
          browser="firefox"
          href="https://www.mozilla.org/firefox/"
          target="_blank"
          rel="noopener"
        >
          <BrowserName>Firefox</BrowserName>
        </BrowserButton>
        <BrowserButton
          browser="safari"
          href="https://support.apple.com/HT204416"
          target="_blank"
          rel="noopener"
        >
          <BrowserName>Safari</BrowserName>
        </BrowserButton>
        <BrowserButton
          browser="opera"
          href="https://www.opera.com/"
          target="_blank"
          rel="noopener"
        >
          <BrowserName>Opera</BrowserName>
        </BrowserButton>
      </Container>
    </>
  );
};

export default BrowserWarning;
