import { createMuiTheme } from "@material-ui/core/styles";

let GROEN = "#42C175";
let DONKERBLAUW = "#2A2135";

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: GROEN,
      dark: "#28c892",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#0b8fd6",
      contrastText: "#ffffff",
    },
    error: {
      main: "#d8003a",
    },
  },
  typography: {
    fontFamily: `Muli, Roboto, sans-serif`,
    useNextVariants: true,
    h4: {
      color: DONKERBLAUW,
      fontSize: 28,
      fontWeight: 300,
    },
    h5: {
      color: GROEN,
      border: `4px solid ${GROEN}`,
      fontWeight: "800",
      padding: "16px",
      textAlign: "center",
    },
    h6: {
      fontWeight: "500",
    },
    button: {
      textTransform: "lowercase",
      fontSize: 16,
      fontWeight: "500",
    },
    caption: {
      fontStyle: "italic",
      paddingTop: "8px",
      textAlign: "center",
    },
    body1: {
      fontStyle: "italic",
    },
    body2: {
      lineHeight: 1.6,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiDivider: {
      root: {
        margin: "20px 0",
      },
    },
  },
});
