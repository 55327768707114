import React from "react";
import gql from "graphql-tag";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import Navigation from "@material-ui/core/BottomNavigation";
import NavigationAction from "@material-ui/core/BottomNavigationAction";
import { TOKEN } from "../constants";
import styled from "styled-components";
import { Todos, Item, ItemTitle, ItemButton } from "./Todos";

const REQUESTS_QUERY = gql`
  query($token: String!, $statusList: [String]!, $amount: Int!, $before: Date) {
    requestsByStatus(
      token: $token
      statusList: $statusList
      amount: $amount
      before: $before
    ) {
      _id
      statusLog {
        status
        activeFrom
        activeUntil
        plannedAt
        linkedObjectId
      }
      steps {
        date
        name
      }
      title
      status
      businessPrivate
      createdAt
      orders {
        _id
        title
        totalPriceIncludingCosts
        status
      }
    }
  }
`;

const RequestCard = ({ item }) => {
  const history = useHistory();

  return (
    <Item key={item._id}>
      <ItemTitle>{item.title}</ItemTitle>
      <ItemButton
        onClick={() => {
          history.push(`/requests/${item._id}`);
        }}
      >
        Tap to open
      </ItemButton>
    </Item>
  );
};

const RequestList = props => {
  const FETCH_AMOUNT = 10;
  const { statusList, name } = props;

  const { data = {} } = useQuery(REQUESTS_QUERY, {
    variables: {
      token: localStorage.getItem(TOKEN),
      statusList,
      name,
      amount: FETCH_AMOUNT,
    },
  });
  const { requestsByStatus: requests = [] } = data;
  return (
    <div>
      {requests
        .filter(({ title }) => Boolean(title))
        .map(request => {
          return <RequestCard key={request._id} item={request} />;
        })}
    </div>
  );
};

const Requests = () => {
  const location = useLocation();

  const statusTypes = {
    OPEN: "open",
    FINISHED: "finished",
  };
  const status = new URLSearchParams(location.search).get("status");

  if (status === statusTypes.OPEN) {
    return (
      <RequestList statusList={["open", "waiting for customer"]} name="open" />
    );
  }

  if (status === statusTypes.FINISHED) {
    return (
      <RequestList statusList={["finished", "canceled"]} name="finished" />
    );
  }

  return <Todos />;
};

export const RequestsView = () => {
  const history = useHistory();
  const location = useLocation();
  const status = new URLSearchParams(location.search).get("status");

  return (
    <div
      style={{
        backgroundColor: "#eef2f5",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
      }}
    >
      <NavigationContainer>
        <Navigation
          showLabels
          onChange={(_, value) => {
            history.push(`/requests?status=${value}`);
          }}
          style={{ borderBottom: "1px solid #d9e4eb" }}
          value={status ? status : "todo"}
        >
          <NavigationAction
            classes={{ label: "label", selected: "selected" }}
            label="Open"
            value="todo"
          />
          <NavigationAction
            classes={{ label: "label", selected: "selected" }}
            label="In-progress"
            value="open"
          />
          <NavigationAction
            classes={{ label: "label", selected: "selected" }}
            label="Closed"
            value="finished"
          />
        </Navigation>
      </NavigationContainer>
      <Requests />
    </div>
  );
};

const NavigationContainer = styled.div`
  .label {
    color: var(--donkergrijs);
    font-weight: bold;
  }

  .selected {
    border-bottom: 3px solid var(--groen);
  }

  .selected span {
    color: var(--groen);
    border-bottom: 0px;
  }
`;
