import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import debounce from "es6-promise-debounce";
import { translate } from "react-i18next";
import Favico from "favico.js";
import moment from "moment";
import styled from "styled-components";

import { withStyles } from "@material-ui/core/styles";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";

import { TOKEN } from "../constants";

import { MainChat } from "./MainChat";
import { RequestChat } from "./RequestChat";
import Request from "./Request";
import { RequestsView } from "./Requests";
import Proposal from "./Proposal";
import Suggestion from "./Suggestion";
import Settings from "./Settings";
import TextButtonWithProgress from "./TextButtonWithProgress";
import AddCredit from "./AddCredit";
import { KnowledgeBase } from "../pages/knowledgeBase";
import EventListener from "react-event-listener";
import { FullScreenDialog } from "./FullScreenDialog";
import { Header } from "./Header";
import { SideBarNavigation } from "./SideBarNavigation";
import { NoWebAppAccessScreen } from "./NoWebAppAccessScreen";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

const SET_ONLINE_STATUS_MUTATION = gql`
  mutation($token: String!, $online: Boolean!) {
    setOnlineStatus(token: $token, online: $online) {
      success
    }
  }
`;

const INVALIDATE_SESSION_MUTATION = gql`
  mutation($token: String!) {
    invalidateSession(token: $token) {
      success
    }
  }
`;

const CLIENT_QUERY = gql`
  # 2
  query($token: String!) {
    client(token: $token) {
      _id
      firstName
      lastName
      companyName
      paidUntil
      credit
      welcomeChatFlowCompleted
      email
      createdAt
      webSessionHoursMax
      dailyHour
      dailyNews
      dailyMinute
      dailyOrders
      dailyWeather
      dailyAppointments
      sendDaily
      onboardingStep
    }
  }
`;

const CLIENT_SUBSCRIPTION = gql`
  subscription($token: String!) {
    clientUpdated(token: $token) {
      _id
      firstName
      lastName
      companyName
      paidUntil
      credit
      welcomeChatFlowCompleted
      email
      createdAt
      webSessionHoursMax
      dailyHour
      dailyNews
      dailyMinute
      dailyOrders
      dailyWeather
      dailyAppointments
      sendDaily
    }
  }
`;

const LOGOUT_NOTIFICATION_SUBSCRIPTION = gql`
  subscription($token: String!) {
    logoutNotification(token: $token) {
      success
    }
  }
`;

const KEEP_ALIVE_SUBSCRIPTION = gql`
  subscription($token: String!) {
    keepAlive(token: $token) {
      success
    }
  }
`;

const UPDATE_SETTINGS_MUTATION = gql`
  mutation(
    $token: String!
    $webSessionHoursMax: Int
    $sendDaily: Boolean
    $dailyHour: Int
    $dailyMinute: Int
    $dailyAppointments: Boolean
    $dailyOrders: Boolean
    $dailyWeather: Boolean
    $dailyNews: Boolean
  ) {
    updateSettings(
      token: $token
      webSessionHoursMax: $webSessionHoursMax
      sendDaily: $sendDaily
      dailyHour: $dailyHour
      dailyMinute: $dailyMinute
      dailyAppointments: $dailyAppointments
      dailyOrders: $dailyOrders
      dailyWeather: $dailyWeather
      dailyNews: $dailyNews
    ) {
      success
      client {
        _id
        firstName
        lastName
        companyName
        paidUntil
        credit
        welcomeChatFlowCompleted
        email
        createdAt
        webSessionHoursMax
        dailyHour
        dailyNews
        dailyMinute
        dailyOrders
        dailyWeather
        dailyAppointments
        sendDaily
      }
    }
  }
`;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: 0,
    zIndex: 1,
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  drawerHeader: { ...theme.mixins.toolbar, marginLeft: 10 },

  outerPaper: {
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  blanket: {
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
  },
  error: {
    color: theme.palette.error.main,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  drawerPaper: {
    overflowY: "hidden",
  },
});

const userAgent = window.navigator.userAgent;

class MainApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      selectedDialog: null,
      logoutDialogOpen: false,
      loggingOut: false,
      locationErrorShown: false,
      unreadMessagesAmount: 0,
      baseTitle: document.title,
      loading: false,
      navLastVisibleIndex: 4,
      fallback: false,
      fallbackShowing: true,
    };
    this.debouncedFunction = debounce(function() {
      return new Promise(function(resolve) {
        resolve();
      });
    }, 1000);
  }

  hideUnreadMessagesAmountBadges() {
    this.setState({
      unreadMessagesAmount: 0,
    });
    document.title = this.state.baseTitle;
    this.favicon.reset();
  }

  closeDialog = () => {
    this.setState({ selectedDialog: null });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentWillMount() {
    this.props.clientQuery.subscribeToMore({
      document: CLIENT_SUBSCRIPTION,
      variables: { token: localStorage.getItem(TOKEN) },
      updateQuery: (prev, { subscriptionData }) => {
        return {
          client: subscriptionData.data.clientUpdated,
        };
      },
    });
  }

  async setOnlineStatus(online) {
    try {
      await this.props.setOnlineStatusMutation({
        variables: {
          online,
        },
      });
      if (online) {
        this.hideUnreadMessagesAmountBadges();
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate() {}

  componentDidMount = () => {
    if (userAgent.indexOf("Edge/") > -1 || userAgent.indexOf("Trident/") > -1) {
      this.setState({ fallback: true });
    } else {
      this.setState({ fallback: false });
    }

    this.props.apolloClient
      .subscribe({
        query: KEEP_ALIVE_SUBSCRIPTION,
        variables: { token: localStorage.getItem(TOKEN) },
      })
      .subscribe(() => {});

    this.props.apolloClient
      .subscribe({
        query: LOGOUT_NOTIFICATION_SUBSCRIPTION,
        variables: { token: localStorage.getItem(TOKEN) },
      })
      .subscribe(() => this.props.onLogout());

    this.favicon = new Favico({
      animation: "slide",
    });

    this.newMessageAudio = new Audio("/sounds/newMessage3.mp3");

    if (document.hasFocus()) {
      this.setOnlineStatus(true);
    }

    if (
      !/Android/i.test(navigator.userAgent) &&
      window.Notification &&
      window.Notification.permission === "default"
    ) {
      window.Notification.requestPermission();
    }
  };

  setOffline() {
    this.setOnlineStatus(false);
  }

  onFocus() {
    if (
      !this.props ||
      !this.props.location ||
      this.props.location.pathname === "/chat"
    ) {
      this.hideUnreadMessagesAmountBadges();
    }
    this.setOnlineStatus(true);
  }

  render() {
    const { t } = this.props;

    const client = this.props.clientQuery.client;

    const locale = navigator.language || navigator.userLanguage;

    const { classes } = this.props;

    const onAddCredit = () => {
      return this.setState({
        selectedDialog: (
          <AddCredit handleRequestClose={() => this.closeDialog()} />
        ),
      });
    };

    const onLogout = () => {
      this.setState({ logoutDialogOpen: true });
    };

    const DashboardWrapper = styled.div`
      position: relative;
      left: 0;
      transition: left 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      flex: 1;
      overflow-y: auto;
      background-color: #eef2f5;

      & .paper .paper-header {
        padding: 8px 20px;
        border-bottom: 2px solid #f4f9f8;
        display: flex;
        align-items: center;
        color: var(--groen);
      }

      & .paper .paper-header h3 {
        color: var(--donkerblauw);
        margin: 5px 0;
      }

      & .paper .paper-header .icon {
        width: 1rem;
        height: 1rem;
      }

      & > ul {
        background: inherit;
        will-change: transform;
        transition: transform 0.2s ease;
      }

      & .paper {
        margin: 0 10px 10px;
      }
    `;
    const DashBoardDrawer = () => {
      return (
        <DashboardWrapper>
          <Switch>
            <Route
              path="/proposal/:id"
              render={props => <Proposal id={props.match.params.id} />}
            />
            <Route
              path="/requests/:id"
              render={props => <Request id={props.match.params.id} />}
            />
            <Route
              path="/suggestion/:id"
              render={props => <Suggestion id={props.match.params.id} />}
            />
            <Route path="/topics" render={() => <KnowledgeBase />} />
            <Route
              exact
              render={() => (
                <Settings
                  client={client}
                  onUpdateSetting={async variables => {
                    await this.props.updateSettingsMutation({
                      variables,
                      optimisticResponse: {
                        updateSettings: {
                          success: true,
                          client: {
                            ...client,
                            ...variables,
                            __typename: "Client",
                          },
                          __typename: "SettingsResult",
                        },
                      },
                    });
                  }}
                />
              )}
              path="/settings"
            />
            <Route
              path={["/requests/:requestId", "/requests", "/"]}
              render={() => <RequestsView />}
            />
          </Switch>
        </DashboardWrapper>
      );
    };

    const isLocalOrDev =
      window.location.hostname &&
      (window.location.hostname === "localhost" ||
        window.location.hostname.includes("ubutler.dev"));
    const showNoWebAccessScreen =
      isLocalOrDev && client?.onboardingStep !== "COMPLETED";

    return (
      <div>
        <FullScreenDialog
          dialog={this.state.selectedDialog}
          onClick={event => {
            if (event.target === event.currentTarget) {
              this.closeDialog();
            }
          }}
        />

        {this.state.fallback && this.state.fallbackShowing && (
          <div className="browser-warning">
            <div>
              <PhosphorIcon name="warning" className="icon" />
              <p>
                Whoops! It looks like you are using an old browser. For the best
                experience we advise you use Chrome or Firefox.
              </p>

              <p style={{ marginTop: "10px" }}>
                Unfortunately, we don't support Internet Explorer any more.
                <br />
                <a
                  alt="say-farewell-to-internet-explorer"
                  href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
                >
                  More information
                </a>
                <br />
                If you are using Internet Explorer, please switch to another
                browser
              </p>

              <p style={{ marginTop: "10px" }}>
                Unfortunately, we don't support Internet Explorer any more.
                <br />
                <a
                  alt="say-farewell-to-internet-explorer"
                  href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
                >
                  More information
                </a>
                <br />
                If you are using Internet Explorer, please switch to another
                browser
              </p>

              <p style={{ marginTop: "10px" }}>
                Unfortunately, we don't support Internet Explorer any more.
                <br />
                <a
                  alt="say-farewell-to-internet-explorer"
                  href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
                >
                  More information
                </a>
                <br />
                If you are using Internet Explorer, please switch to another
                browser
              </p>
              <PhosphorIcon
                name="x"
                className="icon close"
                onClick={() => this.setState({ fallbackShowing: false })}
              />
            </div>
          </div>
        )}

        <Header
          onAddCredit={onAddCredit}
          onLogout={onLogout}
          hideClientDetails={showNoWebAccessScreen}
        />

        {showNoWebAccessScreen && <NoWebAppAccessScreen />}

        {!showNoWebAccessScreen && (
          <main
            style={{
              paddingTop: "56px", // height of the header
            }}
          >
            <EventListener
              target="window"
              onBeforeUnload={this.setOffline.bind(this)}
              onFocus={this.onFocus.bind(this)}
              onBlur={() => this.setOnlineStatus(false)}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "calc(100vh - 56px)",
                backgroundColor: "var(--lichtgrijs)",
                padding: "27px",
                justifyContent: "center",
                minWidth: "820px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "374px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                    overflow: "hidden",
                  }}
                >
                  <SideBarNavigation pathname={this.props.location.pathname} />

                  <Drawer
                    className="dashboard"
                    variant="permanent"
                    open
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    <DashBoardDrawer />
                  </Drawer>
                </div>

                <div
                  style={{
                    flex: 1,
                    borderRadius: "8px",
                    backgroundColor: "white",
                    marginLeft: "18px",
                    height: "100%",
                    minWidth: "374px",
                    maxWidth: "748px",
                  }}
                >
                  <Switch>
                    <Route
                      path="/requests/:id"
                      render={props => (
                        <RequestChat requestId={props.match.params.id} />
                      )}
                    />

                    <Route path="*">
                      <MainChat
                        locale={locale}
                        setMobileOpen={isOpen =>
                          this.setState({ mobileOpen: isOpen })
                        }
                        onLocationError={() =>
                          this.setState({ locationErrorShown: true })
                        }
                        onNewMessage={() => {
                          this.setState({
                            unreadMessagesAmount:
                              this.state.unreadMessagesAmount + 1,
                          });
                          this.favicon.badge(this.state.unreadMessagesAmount);
                          this.newMessageAudio.play().catch(err => {
                            console.log("cannot play audio notification:", err);
                          });
                        }}
                        pageTitle={this.state.baseTitle}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </main>
        )}

        <Dialog
          open={this.state.logoutDialogOpen}
          onClose={() => this.setState({ logoutDialogOpen: false })}
        >
          <DialogTitle id="alert-dialog-title">
            {t("logoutConfirmationTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t("logoutConfirmationText")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ logoutDialogOpen: false })}
              color="primary"
              disabled={this.state.loggingOut}
            >
              {t("no")}
            </Button>

            <TextButtonWithProgress
              onClick={async () => {
                try {
                  this.setState({ loggingOut: true });
                  this.setOnlineStatus(false);
                  await this.props.invalidateSessionMutation();
                  this.hideUnreadMessagesAmountBadges();
                  this.props.onLogout();
                } catch (e) {
                  console.log(e);
                }
              }}
              busy={this.state.loggingOut}
              color="primary"
              text={t("yes")}
            />
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.locationErrorShown}
          message="Your location could not be determined. Please try again."
          autoHideDuration={6000000}
          onClose={() => this.setState({ locationErrorShown: false })}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({ locationErrorShown: false })}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const MainAppWithRouter = withRouter(
  flowRight(
    graphql(INVALIDATE_SESSION_MUTATION, {
      name: "invalidateSessionMutation",
      options: () => ({
        variables: { token: localStorage.getItem(TOKEN) },
      }),
    }),
    graphql(SET_ONLINE_STATUS_MUTATION, {
      name: "setOnlineStatusMutation",
      options: () => ({
        variables: { token: localStorage.getItem(TOKEN) },
      }),
    }),
    graphql(CLIENT_QUERY, {
      name: "clientQuery",
      options: () => ({
        variables: { token: localStorage.getItem(TOKEN) },
      }),
    }),
    graphql(UPDATE_SETTINGS_MUTATION, {
      name: "updateSettingsMutation",
      options: () => ({
        variables: { token: localStorage.getItem(TOKEN) },
        update: (proxy, { data: { updateSettings } }) => {
          proxy.writeQuery({
            query: CLIENT_QUERY,
            variables: { token: localStorage.getItem(TOKEN) },
            data: { client: updateSettings.client },
          });
        },
      }),
    })
  )(withStyles(styles, { withTheme: true })(MainApp))
);

export default translate("MainApp")(MainAppWithRouter);
