import React from "react";
import styled from "styled-components/macro";
import Link from "@material-ui/core/Link";
import apple from "../assets/images/apple.svg";
import googlePlay from "../assets/images/google-play.svg";
import lockWithBackground from "../assets/images/lock-with-background.svg";

export const NoWebAppAccessScreen = () => {
  return (
    <ScreenContainer>
      <ContentContainer>
        <img src={lockWithBackground} />
        <Header>Oops!</Header>
        <Text>
          To provide you with the best assistance, we kindly request that you
          take a brief moment to download the uButler mobile app on your phone
          and first complete the onboarding form before accessing uButler on the web.
        </Text>
        <LinkContainer>
          <Link
            target="_blank"
            rel="noopener"
            rel="noreferrer"
            href={"https://apps.apple.com/us/app/ubutler/id1120195314"}
          >
            <img src={apple} />
          </Link>
          <Link
            target="_blank"
            rel="noopener"
            rel="noreferrer"
            href={"https://play.google.com/store/apps/details?id=nl.ubutler"}
          >
            <img src={googlePlay} />
          </Link>
        </LinkContainer>
      </ContentContainer>
    </ScreenContainer>
  );
};

const ScreenContainer = styled.div`
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  row-gap: 2rem;
`;

const Header = styled.h1`
  font-size: 36px;
  margin: 0;
`;

const Text = styled.p`
  text-align: center;
  line-height: 2.2;
  margin: 0;
  max-width: 478px;
`;

const LinkContainer = styled.div`
  display: flex;
  column-gap: 1rem;
`;
