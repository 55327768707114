import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import logoImage from "../assets/images/ubutler-logo.svg";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import InputAdornment from "@material-ui/core/InputAdornment";
import { translate } from "react-i18next";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
});

class LoginPage extends Component {
  state = {
    phone: "",
    verificationId: null,
    code: "",
    error: null,
    email: "",
    loginMethod: "email",
  };

  moveCaretAtEnd(e) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={logoImage}
            alt="uButler"
            style={{ width: "15em", marginBottom: 40 }}
          />
          <div>
            {!this.state.verificationId ? (
              <form
                onSubmit={e => {
                  e.preventDefault();

                  if (this.state.loginMethod === "email") {
                    if (!this.isEmailValid()) {
                      return;
                    }

                    this._startEmailVerification();
                  } else {
                    if (!this.isPhoneValid()) {
                      return;
                    }

                    this._startSmsVerification();
                  }
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "center",
                }}
              >
                {this.state.loginMethod === "sms" ? (
                  <TextField
                    disabled={this.state.submitting}
                    autoFocus
                    onFocus={this.moveCaretAtEnd}
                    className={classes.button}
                    id="search"
                    label={t("phoneNumber")}
                    type="tel"
                    margin="none"
                    value={this.state.phone}
                    onChange={e => {
                      let phone = e.target.value;

                      phone = phone.replace(/\D/g, "");
                      if (!phone.match(/^[0-9]{0,16}$/)) {
                        return;
                      }

                      this.setState({ phone });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+</InputAdornment>
                      ),
                    }}
                    style={{
                      margin: "0",
                    }}
                  />
                ) : (
                  <TextField
                    disabled={this.state.submitting}
                    autoFocus
                    onFocus={this.moveCaretAtEnd}
                    className={classes.button}
                    id="search"
                    label={"Email"}
                    type="email"
                    value={this.state.email}
                    onChange={e => {
                      let email = e.target.value;
                      this.setState({ email });
                    }}
                    style={{
                      margin: "0",
                    }}
                  />
                )}

                <p
                  onClick={() => {
                    this.setState(prevState => ({
                      loginMethod:
                        prevState.loginMethod === "email" ? "sms" : "email",
                    }));
                  }}
                  style={{
                    color: "#072533",
                  }}
                >
                  or{" "}
                  <span
                    style={{
                      color: "#072533",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.loginMethod === "email"
                      ? "login via sms"
                      : "login via email"}
                  </span>
                </p>

                <br />

                <Button
                  type="submit"
                  variant="contained"
                  disableFocusRipple
                  disabled={
                    this.state.submitting || this.state.loginMethod === "email"
                      ? !this.isEmailValid()
                      : !this.isPhoneValid()
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  size="medium"
                >
                  {this.state.submitting ? "sending code..." : "login"}
                </Button>
              </form>
            ) : (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!this.isCodeValid()) {
                    return;
                  }
                  this._completeVerification();
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "center",
                }}
              >
                <TextField
                  disabled={this.state.submitting}
                  autoFocus
                  error={this.state.error != null}
                  helperText={this.state.error}
                  className={classes.button}
                  id="search"
                  label={"Code"}
                  type="text"
                  value={this.state.code}
                  onChange={e => {
                    let code = e.target.value;
                    if (!code.match(/^[0-9]{0,6}$/)) {
                      return;
                    }
                    this.setState({
                      code: code.toUpperCase(),
                      error: null,
                    });
                  }}
                  style={{
                    margin: "0",
                  }}
                />

                <p
                  onClick={() => {
                    if (this.state.submitting) return;

                    this.setState({
                      verificationId: null,
                      code: "",
                    });
                  }}
                  style={{
                    color: "#072533",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Back
                </p>

                <br />

                <Button
                  type="submit"
                  variant="contained"
                  disableFocusRipple
                  disabled={!this.isCodeValid()}
                  style={{
                    cursor: "pointer",
                  }}
                  size="medium"
                >
                  {this.state.submitting ? "verifying..." : "verify"}
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    const field = document.getElementsByTagName("input")[0];
    if (field) {
      field.focus();
    }
  }

  isPhoneValid = () => {
    return this.state.phone.match(/^[0-9]{10,16}$/);
  };

  isEmailValid = () => {
    return this.state.email.match(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
  };

  isCodeValid = () => {
    return this.state.code.match(/^[A-Z0-9]{6}$/);
  };

  _startEmailVerification = async () => {
    this.setState({ submitting: true });

    try {
      const { email } = this.state;
      const result = await this.props.startEmailVerificationMutation({
        variables: {
          email,
          sessionType: "web",
        },
      });
      this.setState({
        verificationId: result.data.startEmailVerification._id,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  };

  _startSmsVerification = async () => {
    this.setState({ submitting: true });

    try {
      const { phone } = this.state;
      const result = await this.props.startSmsVerificationMutation({
        variables: {
          phone: `+${phone}`,
          sessionType: "web",
        },
      });
      this.setState({
        verificationId: result.data.startSmsVerification._id,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  };

  _completeVerification = async () => {
    const { t } = this.props;
    this.setState({ submitting: true });

    try {
      const { code, verificationId } = this.state;
      const result = await this.props.completeVerificationMutation({
        variables: {
          verificationId,
          code,
        },
      });

      const answer = result.data.completeVerification;

      if (answer.success) {
        this.props.onLogin(result.data.completeVerification.token);
      } else {
        this.setState({ error: t("invalidCode") });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  };
}

const START_EMAIL_VERIFICATION_MUTATION = gql`
  mutation($email: String!, $sessionType: String!) {
    startEmailVerification(email: $email, sessionType: $sessionType) {
      _id
    }
  }
`;

const START_SMS_VERIFICATION_MUTATION = gql`
  mutation($phone: String!, $sessionType: String!) {
    startSmsVerification(phone: $phone, sessionType: $sessionType) {
      _id
    }
  }
`;

const COMPLETE_SMS_VERIFICATION_MUTATION = gql`
  mutation($verificationId: String!, $code: String!) {
    completeVerification(_id: $verificationId, code: $code) {
      success
      token
    }
  }
`;

const LoginPageWithGraphql = flowRight(
  graphql(START_EMAIL_VERIFICATION_MUTATION, {
    name: "startEmailVerificationMutation",
  }),
  graphql(START_SMS_VERIFICATION_MUTATION, {
    name: "startSmsVerificationMutation",
  }),
  graphql(COMPLETE_SMS_VERIFICATION_MUTATION, {
    name: "completeVerificationMutation",
  })
)(withStyles(styles)(LoginPage));

export default translate("LoginPage")(LoginPageWithGraphql);
