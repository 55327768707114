import config from "../config";

class StripeService {
  constructor(token) {
    this._stripeClient = window.Stripe(token);
  }

  redirectToCheckout(sessionId, router) {
    this._stripeClient
      .redirectToCheckout({
        sessionId,
      })
      .then(function(result) {
        router.push("/pay/result/oops");
      });
  }

  finishPayment(secret, cardId, router) {
    this._stripeClient
      .handleCardPayment(secret, {
        payment_method: cardId,
      })
      .then(function(result) {
        if (result.error) {
          router.push("/pay/result/payment-failed");
        } else {
          router.push("/pay/result/payment-success");
        }
      });
  }
}

export const stripeService = new StripeService(config.stripePublishableKey);
