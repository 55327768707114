import React from "react";
import styled from "styled-components/macro";
import ArrowForward from "@material-ui/icons/ArrowForward";

export const LongButton = ({
  className,
  children,
  disabled,
  title,
  onClick,
  backgroundColor,
  textColor,
  backgroundHoverColor,
  textHoverColor,
  icon = <ArrowForward style={{ color: "currentColor" }} />,
}) => {
  return (
    <LongLink
      className={className}
      onClick={onClick}
      backgroundColor={disabled ? "#657786" : backgroundColor}
      backgroundHoverColor={disabled ? "#657786" : backgroundHoverColor}
    >
      <LongLinkButton
        disabled={disabled}
        textColor={textColor}
        textHoverColor={textHoverColor}
      >
        <span>{title}</span>
        {icon}
      </LongLinkButton>

      {children}
    </LongLink>
  );
};

export const LongLink = styled.section`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #072533;
  background-color: ${props => props.backgroundColor || "#ffffff"};
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.backgroundHoverColor || "#e1e5e7"};
  }
`;

const LongLinkButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 15px;
  border: 0;
  background: none;
  text-decoration: none;
  outline: 0;
  cursor: ${props => (props.disabled ? "initial" : "inherit")};
  font-family: "Muli", sans-serif;

  font-size: 16px;
  font-weight: bold;
  color: ${props => props.textColor || "#072533"};

  &:hover {
    color: ${props => props.textHoverColor || "#072533"};
  }
`;
