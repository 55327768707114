import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import React from "react";
import gql from "graphql-tag";
import { flowRight } from "lodash";
import { withRouter } from "react-router";
import { Background } from "../components/styled/Background";
import { stripeService } from "../services/stripeService";
import { graphql } from "react-apollo";

const EXTERNAL_PAYMENT_QUERY = gql`
  query($id: String!, $clientId: String!) {
    externalPayment(_id: $id, clientId: $clientId) {
      type
      sessionId
      secret
      cardId
    }
  }
`;

const redirectTypes = {
  NEW_CREDIT_CARD: "new_credit_card",
  ONE_TIME_PAYMENT: "one_time_payment",
  AUTOMATIC_CHARGE: "automatic_charge",
};

class ExternalPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      busy: false,
    };
  }

  handleButtonPress = () => {
    this.setState({ busy: true });

    if (this.props.externalPaymentQuery.externalPayment == null) {
      return window.close();
    }

    const {
      type,
      sessionId,
      secret,
      cardId,
    } = this.props.externalPaymentQuery.externalPayment;

    if (
      type === redirectTypes.NEW_CREDIT_CARD ||
      type === redirectTypes.ONE_TIME_PAYMENT
    ) {
      return stripeService.redirectToCheckout(sessionId, this.props.history);
    }

    if (type === redirectTypes.AUTOMATIC_CHARGE) {
      return stripeService.finishPayment(secret, cardId, this.props.history);
    }

    return window.close();
  };

  getTexts() {
    const type = this.props.externalPaymentQuery.externalPayment?.type ?? null;

    switch (type) {
      case redirectTypes.ONE_TIME_PAYMENT:
        return {
          title: "One time payment",
          body:
            "We will redirect you to our partner Stripe to fulfill the payment. We will not store your credit card details.",
          buttonText: "Start payment",
        };
      case redirectTypes.NEW_CREDIT_CARD:
        return {
          title: "Add a credit card",
          body:
            "We will redirect you to our partner Stripe to fill in your credit card details.",
          buttonText: "Add new credit card",
        };
      case redirectTypes.AUTOMATIC_CHARGE:
        return {
          title: "Confirm payment",
          body:
            "Your bank is asking for extra confirmation for this payment. We will redirect you to our partner Stripe to complete the payment.",
          buttonText: "Finish payment",
        };
      default:
        return {
          title: "Oops",
          body:
            "Something seems to have gone wrong. Contact Sophie if you need further assistance.",
          buttonText: "Close",
        };
    }
  }

  render() {
    const { loading } = this.props.externalPaymentQuery;

    if (loading) {
      return (
        <Background>
          <p>Loading...</p>
        </Background>
      );
    }

    const { title, body, buttonText } = this.getTexts();

    return (
      <Background>
        <Paper style={{ width: "400px", padding: "16px 20px 12px" }}>
          <h2 style={{ margin: 0 }}>{title}</h2>

          <p
            style={{ fontSize: "16px", lineHeight: 1.5, margin: "20px 0 30px" }}
          >
            {body}
          </p>

          <Button
            variant="contained"
            color="primary"
            onClick={this.handleButtonPress}
            style={{ textTransform: "none" }}
            disabled={this.state.busy}
          >
            {this.state.busy ? (
              <CircularProgress size={20} style={{ margin: "6px 16px" }} />
            ) : (
              buttonText
            )}
          </Button>
        </Paper>
      </Background>
    );
  }
}

export const ExternalPaymentPage = flowRight(
  graphql(EXTERNAL_PAYMENT_QUERY, {
    name: "externalPaymentQuery",
    options: ({ externalPaymentId, clientId }) => ({
      variables: { id: externalPaymentId, clientId },
    }),
  }),
  withRouter
)(ExternalPayment);
