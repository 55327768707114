import React from "react";
import dompurify from "dompurify";
import styled from "styled-components";
import { YouTube } from "./YouTube";

export let HTMLContent = ({ body }) => {
  return (
    <ContentLayout>
      <TextSection
        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(body) }}
      />
    </ContentLayout>
  );
};

export const Content = ({ content, title }) => {
  return (
    <ContentLayout>
      <h2>{title}</h2>
      {content.map(contentInfo => {
        switch (contentInfo.type) {
          case "text":
            const body = contentInfo.body;
            return (
              <TextSection
                key={contentInfo._id}
                dangerouslySetInnerHTML={{ __html: dompurify.sanitize(body) }}
              />
            );
            break;
          case "image":
            const image = contentInfo.url;
            return (
              <Image key={contentInfo._id} src={dompurify.sanitize(image)} />
            );
            break;
          case "youtube":
            return (
              <div style={{ backgroundColor: "rgb(238, 242, 245)" }}>
                <YouTube key={contentInfo._id} videoId={contentInfo.videoId} />
              </div>
            );
            break;
          default:
            return null;
        }
      })}
    </ContentLayout>
  );
};

const Image = styled.img`
  width: 100%;
  margin: 20px 0px;
`;

const TextSection = styled.section`
  color: #2a2135;
  opacity: 0.75;

  ul {
    margin-left: 1em;
  }
  li {
    margin: 0;
    padding-left: 0;

    li + & {
      margin-top: 8px;
    }
  }
`;

const ContentLayout = styled.article`
  /* opacity: 0.75; */
  font-size: inherit;
  font-weight: 600;
  background-color: white;
  h2 {
    font-family: "Muli", sans-serif;
    font-size: 1.2em;
    font-weight: 800;
    color: #072533;
  }
  strong {
    font-weight: 800;
    color: black;
  }
`;
