import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import LocationOn from "@material-ui/icons/LocationOn";
import Photo from "@material-ui/icons/Photo";
import dompurify from "dompurify";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import GoogleMapReact from "google-map-react";
import { AllHtmlEntities } from "html-entities";
import marked from "marked";
import moment from "moment";
import "moment/locale/nl";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import EventListener from "react-event-listener";
import { translate } from "react-i18next";
import strip from "strip";

import styled from "styled-components";
import twemoji from "twemoji";
import * as uuid from "uuid";
import config from "../config";
import CenteredProgress from "./CenteredProgress";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMore: false,
      lastScrolledId: null,
      lastBefore: null,
      showEmojiPicker: false,
      inputBarHeight: 60,
      initialImagesHaveLoaded: false,
      shouldRefocus: true,
      menuAnchor: null,
    };

    moment.locale(props.locale);
  }

  componentWillReceiveProps(nextProps) {
    moment.locale(nextProps.locale);
    if (!this.props.messages || this.props.messages.length === 0) {
      return;
    }
    const oldLastMessage = this.props.messages[this.props.messages.length - 1];
    const newLastMessage = nextProps.messages[nextProps.messages.length - 1];

    if (oldLastMessage.options && !newLastMessage.options) {
      this.setState({
        shouldRefocus: true,
      });
    }
  }

  unicodeToHtml(val) {
    val = entities.encode(val);
    val = val.replace(/\n/g, "<br>");
    val = twemoji.parse(val);
    return val;
  }

  htmlToUnicode(text) {
    text = text.replace(/<img [^>]* alt="([^"]*)"[^>]*>/g, "$1");
    text = text.replace(/<br>/g, "\n");
    text = strip(text);
    text = entities.decode(text);
    return text;
  }

  render() {
    const lastMessage = this.props.messages
      ? this.props.messages[this.props.messages.length - 1]
      : null;
    const { t } = this.props;
    return (
      <div
        style={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          flex: 1,
        }}
      >
        <EventListener
          target="window"
          onResize={this.scrollToBottom.bind(this)}
        />
        {this.state.showEmojiPicker && !lastMessage.options && (
          <div
            style={{
              zIndex: 2000, // Needs to be really high because of the sidebar.
              height: "100%", // Same height as the chat window.
              position: "relative", // Resets stacking context so we can use more sane z-indexes.
            }}
          >
            <EmojiDismissOverlay
              onClick={() => {
                this.setState({ showEmojiPicker: false });
              }}
            />
            <Picker
              set="twitter"
              style={{
                position: "absolute",
                bottom: this.state.inputBarHeight,
                zIndex: 1,
              }}
              autoFocus={true}
              title=""
              emoji=""
              onClick={(emoji, event) => {
                ReactDOM.findDOMNode(
                  this.refs.contentEditable
                ).innerHTML = this.unicodeToHtml(
                  this.htmlToUnicode(
                    ReactDOM.findDOMNode(this.refs.contentEditable).innerHTML
                  ) + emoji.native
                );

                this.updateInputBarHeight();
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 0,
            background: "white",
            bottom: this.state.inputBarHeight,
            overflow: "hidden",
            width: "100%",
          }}
        >
          {this.props.chatHeader && (
            <ChatHeader>{this.props.chatHeader}</ChatHeader>
          )}
          {this.renderMessageList()}
        </div>
        <ChatInputBar ref="inputBar">
          {this.props.allowInput ? (
            <React.Fragment>
              <IconButton
                style={{
                  display: "flex",
                  marginBottom: 4,
                }}
                color="primary"
                onClick={event => {
                  this.setState({
                    menuAnchor: event.currentTarget,
                  });
                }}
                disabled={Boolean(lastMessage?.options)}
              >
                <PhosphorIcon name="paperclip" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.menuAnchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={this.state.menuAnchor !== null}
                onClose={() => this.setState({ menuAnchor: null })}
              >
                <MenuItem
                  disabled={true}
                  onClick={() => {
                    this.setState({ menuAnchor: null });
                    this.props.onSendImage();
                  }}
                >
                  <ListItemIcon>
                    <Photo />
                  </ListItemIcon>
                  <ListItemText inset primary={t("image")} />
                </MenuItem>
                {navigator.geolocation && (
                  <MenuItem
                    onClick={() => {
                      this.setState({ menuAnchor: null });
                      this.props.onSendLocation();
                    }}
                  >
                    <ListItemIcon>
                      <LocationOn />
                    </ListItemIcon>
                    <ListItemText inset primary={t("location")} />
                  </MenuItem>
                )}
              </Menu>
              <IconButton
                color="primary"
                type="submit"
                style={{
                  zIndex: 1,
                  flexGrow: "1",
                  marginBottom: 4,
                  width: 50,
                  height: 50,
                }}
                disabled={Boolean(lastMessage?.options)}
                onClick={async () => {
                  this.updateInputBarHeight();
                  this.setState({
                    showEmojiPicker: !this.state.showEmojiPicker,
                  });
                }}
              >
                {this.state.showEmojiPicker ? (
                  <PhosphorIcon name="caret-down" />
                ) : (
                  <PhosphorIcon name="smiley" />
                )}
              </IconButton>

              <IconButton
                color="primary"
                type="submit"
                style={{
                  zIndex: 1,
                  flexGrow: "1",
                  marginBottom: 4,
                  width: 50,
                  height: 50,
                  order: "99",
                }}
                disabled={Boolean(lastMessage?.options)}
                onClick={async () => {
                  this.submitText(
                    this.htmlToUnicode(
                      ReactDOM.findDOMNode(this.refs.contentEditable).innerHTML
                    )
                  );
                }}
              >
                <PhosphorIcon name="paper-plane" />
              </IconButton>
              <div
                style={{
                  flexGrow: "99",
                  width: "calc(100% - 100px)",
                  padding: "10px 0",
                }}
              >
                {!lastMessage?.options && (
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      borderRadius: "5px",
                      maxHeight: 500,
                    }}
                  >
                    <ChatInput
                      contentEditable
                      ref="contentEditable"
                      onKeyDownCapture={e => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                          e.preventDefault();
                          e.stopPropagation();
                          this.submitText(
                            this.htmlToUnicode(
                              ReactDOM.findDOMNode(this.refs.contentEditable)
                                .innerHTML
                            )
                          );
                        }
                      }}
                      onChange={() => this.updateInputBarHeight()}
                      onKeyUp={() => this.updateInputBarHeight()}
                      onInput={() => this.updateInputBarHeight()}
                      autoFocus
                    />
                  </div>
                )}
                {lastMessage?.options && (
                  <div style={{ width: "100%" }}>
                    {lastMessage.options.map(option => (
                      <Button
                        style={{
                          margin: "2px",
                          backgroundColor: clientMsgColor,
                          color: "white",
                          textTransform: "none",
                        }}
                        key={option}
                        variant="contained"
                        onClick={() => {
                          this.submitText(option);
                        }}
                      >
                        {option}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div style={{ display: "flex", margin: "auto" }}>
              <h3>{this.props.serviceMessage}</h3>
            </div>
          )}
        </ChatInputBar>
      </div>
    );
  }

  renderMessageList() {
    if (!this.props.messages || this.props.messages.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
          }}
        >
          No messages yet
        </div>
      );
    }
    const { t } = this.props;
    const messages = this.props.messages.map((msg, index) => {
      return (
        <ChatMessage
          key={msg._id}
          className={msg.sentByUser ? "user" : "system"}
          style={{
            width: "auto",
            maxWidth: msg.type === "image" ? "400px" : "auto",
          }}
          onLoad={() => {
            if (this.props.messages.length + 1 - index < 10) {
              this.scrollToBottom();
            }
          }}
        >
          {msg.type === "text" && (
            <div
              style={{
                color: "inherit",
                lineHeight: "1.3",
                margin: 0,
              }}
              dangerouslySetInnerHTML={{
                __html: marked(
                  dompurify.sanitize(twemoji.parse(msg.content)).toString(),
                  {
                    renderer: msg.sentByUser ? clientRenderer : vbRenderer,
                  }
                ),
              }}
            />
          )}
          {msg.type === "image" && msg.url && msg.url !== "" && (
            <div
              ref={"div" + msg._id}
              style={{
                marginBottom: 20,
              }}
            >
              <img
                onError={() => {
                  setTimeout(
                    () =>
                      (ReactDOM.findDOMNode(this.refs["img" + msg._id]).src =
                        msg.url + "?" + new Date().getTime()),
                    1000
                  );
                }}
                ref={"img" + msg._id}
                onLoad={() => {
                  // IE image resize bug workaround (by default, parent sizes to original size of image instead of resized size in IE11)
                  ReactDOM.findDOMNode(
                    this.refs["imgSpinner" + msg._id]
                  ).style.display = "none";
                  ReactDOM.findDOMNode(
                    this.refs["img" + msg._id]
                  ).style.display = "inline";
                  const imgHeight = ReactDOM.findDOMNode(
                    this.refs["img" + msg._id]
                  ).clientHeight;
                  ReactDOM.findDOMNode(
                    this.refs["div" + msg._id]
                  ).style.height = imgHeight + "px";

                  if (this.props.messages.length + 1 - index < 10) {
                    this.scrollToBottom();
                  }

                  if (!this.state.initialImagesHaveLoaded) {
                    setTimeout(() => this.scrollToBottom(), 1000);

                    const imgs = Array.prototype.slice.call(
                      ReactDOM.findDOMNode(
                        this.refs.messageList
                      ).getElementsByTagName("img")
                    );

                    const remainingAmount = imgs.filter(img => !img.complete)
                      .length;

                    if (remainingAmount === 0) {
                      this.setState({
                        initialImagesHaveLoaded: true,
                      });
                    }
                  }
                }}
                alt=""
                src={msg.url}
                style={{
                  display: "none",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  marginTop: 10,
                }}
              />
            </div>
          )}
          {msg.type === "image" && msg.status !== "failed" && (
            <div
              style={{
                marginBottom: 20,
              }}
              ref={"imgSpinner" + msg._id}
            >
              <CenteredProgress
                style={{
                  paddingTop: 10,
                }}
              />
            </div>
          )}
          {msg.type === "image" && msg.status === "failed" && (
            <div
              style={{
                margin: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              <ErrorIcon />
            </div>
          )}
          {msg.type === "location" && (
            <div
              style={{
                display: "inline-block",
                width: 300,
                height: 300,
                marginTop: 10,
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: config.googleMapsKey,
                }}
                center={{
                  lat: msg.latitude,
                  lng: msg.longitude,
                }}
                defaultZoom={15}
              >
                <Marker lat={msg.latitude} lng={msg.longitude} />
              </GoogleMapReact>
            </div>
          )}
          <div style={{ textAlign: msg.sentByUser ? "left" : "right" }}>
            <MessageDate>
              {moment(msg.createdAt).calendar(null, {
                sameElse: "dddd DD-MM-YYYY hh:mm A",
              }) + " "}
              {msg.sentByUser && (
                <span>
                  <Tooltip title={msg.status}>
                    <Icon
                      style={{
                        fontSize: "inherit",
                        paddingTop: 2,
                      }}
                    >
                      {msg.status === "sending" ||
                      msg.status === "waiting for content"
                        ? "access_time"
                        : "check"}
                    </Icon>
                  </Tooltip>
                </span>
              )}
            </MessageDate>
          </div>
        </ChatMessage>
      );
    });

    let unreadMessagesString = "";

    if (this.props.firstUnreadMsgId) {
      const firstUnreadMsgIndex = this.props.messages.findIndex(
        msg => msg._id === this.props.firstUnreadMsgId
      );
      if (firstUnreadMsgIndex) {
        const unreadMessagesAmount =
          this.props.messages.length - firstUnreadMsgIndex;

        if (unreadMessagesAmount === 1) {
          unreadMessagesString = t("oneUnreadMsg");
        } else {
          unreadMessagesString =
            unreadMessagesAmount + " " + t("unreadMessages");
        }
      }

      messages.splice(
        firstUnreadMsgIndex,
        0,
        <div
          key="unreadDivider"
          style={{
            textAlign: "center",
            height: 40,
            margin: `20px 0 15px`,
          }}
        >
          <span
            style={{
              width: `100%`,
              display: `block`,
              padding: `10px 0`,
              background: `#efefef`,
            }}
          >
            {unreadMessagesString}
          </span>
        </div>
      );
    }

    return (
      <ChatContainer
        haschatheader={String(this.props.chatHeader)}
        onScroll={e => {
          if (this.props.loadMoreMessages && e.target.scrollTop === 0) {
            e.target.scrollTop = 1;

            const before = this.props.messages[0].createdAt;
            if (this.state.lastBefore && this.state.lastBefore === before) {
              return;
            }
            this.setState({
              lastBefore: before,
              loadingMore: true,
            });
            this.props.loadMoreMessages(before, this);
          }
        }}
        ref="messageList"
      >
        {this.state.loadingMore && (
          <CenteredProgress
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          />
        )}
        {messages}
        <div
          ref="bottomElement"
          style={{
            margin: 0,
            marginBottom: 10,
            display: "inline-block",
            fontSize: 1,
          }}
        />
      </ChatContainer>
    );
  }

  async submitText(text) {
    text = text.replace(/[\n ]*$/g, "");
    text = text.replace(/^[\n ]*/g, "");

    this.setState({
      showEmojiPicker: false,
    });

    this.updateInputBarHeight(60);
    if (text === "") {
      return;
    }

    const inputNode = ReactDOM.findDOMNode(this.refs.contentEditable);
    if (inputNode) {
      inputNode.innerHTML = "";
    }

    this.props.onSubmitMessage({
      content: text,
      externalId: uuid.v4(),
    });
  }

  scrollToBottom() {
    if (
      this.props.loadingMessages ||
      !this.props.messages ||
      this.props.messages.length === 0
    ) {
      return;
    }

    // always scroll to the bottom of the message list
    const messageList = ReactDOM.findDOMNode(this.refs.messageList);
    const lastMessageElement = ReactDOM.findDOMNode(this.refs.bottomElement);

    if (!messageList || !lastMessageElement) {
      return;
    }

    const scrollHeight = messageList.scrollHeight;
    const height = messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;

    messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  updateInputBarHeight(inputBarHeight) {
    if (!inputBarHeight) {
      const elem = ReactDOM.findDOMNode(this.refs.inputBar);
      if (elem) {
        inputBarHeight = elem.offsetHeight;
      }
    }

    if (!inputBarHeight) {
      return;
    }

    if (this.state.inputBarHeight !== inputBarHeight) {
      this.setState({
        inputBarHeight,
      });
    }
  }

  componentDidUpdate() {
    this.updateInputBarHeight();
    const elem = ReactDOM.findDOMNode(this.refs.contentEditable);
    if (this.state.shouldRefocus && elem) {
      this.setState({
        shouldRefocus: false,
      });
      elem.focus();
    }

    if (!this.props.messages) {
      return;
    }

    const lastMessage = this.props.messages[this.props.messages.length - 1];

    if (!lastMessage) {
      return;
    }

    if (this.state.shouldRefocus && !lastMessage.options) {
      this.setState({
        shouldRefocus: false,
      });
      const input = ReactDOM.findDOMNode(this.refs.contentEditable);
      if (input) {
        input.focus();
      }
    }

    if (
      this.state.lastScrolledId &&
      this.state.lastScrolledId === lastMessage._id
    ) {
      return;
    }
    this.setState({
      lastScrolledId: lastMessage._id,
    });
    this.scrollToBottom();
  }

  componentDidMount() {
    this.updateInputBarHeight();
    this.scrollToBottom();
    setTimeout(() => {
      // IE workaround
      this.scrollToBottom();
    }, 1000);
  }
}

export default translate("Chat")(Chat);

let MessageDate = styled.span`
  color: inherit;
  margin: 0;
  padding: 0;
  font-style: italic;
  font-size: 11px;
  opacity: 0.6;
`;

let ChatMessage = styled.div`
  font-size: 13px;
  margin: 5px 0 0;
  padding: 0 10px 10px;
  text-align: left;
  position: relative;
  border-radius: 10px;
  display: block;
  color: var(--donkerblauw);
  max-width: 70%;

  /* Else flexbox will take away the padding for long messages */
  box-sizing: content-box;

  &:first-of-type {
    margin-top: auto;
  }

  & ol,
  & ul {
    padding: 0;
    margin-left: 17px;
  }

  & ol li,
  & ul li {
    margin: 0;
    padding: 0;
  }

  &.user {
    align-self: flex-end;
    color: white;
    /* border-top-right-radius: 0px; */
    margin-right: 20px;
    padding-right: 10px;
    background-color: #42c175;
    /* box-shadow: -1px 1px 1px 0px rgba(161, 161, 161, 1); */
  }

  &.user + &:not(.user),
  &.system + &:not(.system) {
    margin-top: 20px;
  }

  &.user + &.user,
  &.system + &.system {
    border-radius: 8px;
  }

  &.system {
    align-self: flex-start;
    /* border-top-left-radius: 0px; */
    margin-left: 20px;
    /* padding-left: 8px; */
    background: var(--lichtgrijs);
  }

  & p {
    margin: 8px 0 2px;
    position: relative;
    z-index: 6;
  }

  & p + p {
    margin-top: 16px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

let ChatInputBar = styled.div`
  min-height: 60px;
  /* background: #efefef; */
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  padding-left: 10px;
  padding-right: 5px;

  background-color: white;

  /* border-bottom-right-radius: 8px; */
`;

let ChatInput = styled.div`
  background-color: var(--lichtgrijs);
  min-height: 40px;
  max-height: 300px;
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 5px;
  font-size: 13px;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

const headerHeight = "30px";
const ChatHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${headerHeight};
  background-color: #f7fafc;
`;

let ChatContainer = styled(List)`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(
    100% - ${props => (props.haschatheader === "true" ? headerHeight : "0px")}
  );
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 0;
`;

const EmojiDismissOverlay = styled.div`
  z-index: 0;
  background: rgba(255, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const entities = new AllHtmlEntities();

const vbRenderer = new marked.Renderer();
vbRenderer.link = (href, title, text) =>
  `<a target="_blank" style="color: black;" href="${href}" title="${title}">${text}</a>`;

const clientRenderer = new marked.Renderer();
clientRenderer.link = (href, title, text) =>
  `<a target="_blank" style="color: white;" href="${href}" title="${title}">${text}</a>`;

marked.setOptions({
  breaks: true,
});

const clientMsgColor = "var(--groen)";

class Marker extends Component {
  render() {
    return <LocationOn color="error" />;
  }
}
