import React from "react";
import styled from "styled-components";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

export const HeaderName = ({ firstName, lastName, onClick }) => {
  return (
    <MainDiv onClick={onClick}>
      <AccountCircleIcon className="account-circle-icon" />
      <h3>{(firstName || "") + " " + (lastName || "")}</h3>
      <PhosphorIcon name="caret-down"/>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;

  .account-circle-icon {
    color: var(--groen);
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  .angle-down-icon {
    color: #9cadba;
    margin-left: 14px;
  }

  > * {
    display: block;
  }
`;
