import React, { Component } from "react";
import gql from "graphql-tag";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import { TOKEN } from "../constants";
import Button from "@material-ui/core/Button";
import { translate } from "react-i18next";
import CenteredProgress from "./CenteredProgress";
import { withRouter, Link } from "react-router-dom";
import marked from "marked";
import dompurify from "dompurify";

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text);
  return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
};

const SUGGESTION_QUERY = gql`
  query($token: String!, $suggestionId: ID!) {
    suggestion(token: $token, suggestionId: $suggestionId) {
      _id
      title
      description
      activeUntil
      priority
      credit
      isActive
      timesUsable
      expectedJobDurationUnit
      expectedJobDurationAmount
      expectedPrice
    }
  }
`;

const REDEEM_SUGGESTION_MUTATION = gql`
  mutation($token: String!, $suggestionId: ID!) {
    redeem_item(token: $token, suggestionId: $suggestionId) {
      success
      requestId
    }
  }
`;

class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submitted: false,
    };
  }

  render() {
    if (this.props.suggestionQuery.loading) {
      return (
        <div
          style={{
            flex: 1,
            height: "100%",
          }}
        >
          <CenteredProgress />
        </div>
      );
    }
    const suggestion = this.props.suggestionQuery.suggestion;
    const { t } = this.props;

    const details = [];

    return (
      <div
        style={{ height: "100%", overflow: "auto" }}
        className="suggestionView"
      >
        <div className="paper">
          <div className="paper-header">
            <h3 style={{ margin: 0 }}>{suggestion.title}</h3>
          </div>
          <div style={{ padding: "10px 20px" }}>
            <div
              style={{ marginBottom: 30 }}
              dangerouslySetInnerHTML={{
                __html: marked(dompurify.sanitize(suggestion.description), {
                  renderer,
                }),
              }}
            />
            {details.length > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  margin: "0 -5px",
                }}
              >
                {details.map((detail, index) => (
                  <div key={index} className="suggestion-addon">
                    <section>
                      {this.state.overflown && (
                        <div
                          style={{
                            background:
                              "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)",
                            height: 40,
                            position: "absolute",
                            width: "100%",
                            zIndex: "9",
                            bottom: "0",
                          }}
                        />
                      )}
                      <h3
                        style={{
                          textAlign: "center",
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 18,
                        }}
                      >
                        {detail.icon}
                      </h3>
                    </section>
                    <section className="specs">
                      <p>{detail.key}:</p>
                      <p
                        style={{
                          overflow: "hidden",
                          width: "100%",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {detail.value}
                      </p>
                    </section>
                  </div>
                ))}
              </div>
            )}
            {this.state.submitting && !this.state.submitted ? (
              <CenteredProgress />
            ) : (
              <div>
                {!this.state.submitted ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      color="secondary"
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ submitting: true });
                        this.redeemSuggestion();
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t("askSophie")}
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      color="secondary"
                    >
                      {t("goBack")}
                    </Button>
                    <Link
                      to={"/requests/" + this.state.requestId}
                      style={{ textDecoration: "none" }}
                    >
                      <Button color="primary" variant="contained">
                        {t("goToRequest")}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  async redeemSuggestion(suggestion) {
    const { redeemSuggestionMutation } = this.props;
    const redeem = await redeemSuggestionMutation({
      variables: {
        token: localStorage.getItem(TOKEN),
        suggestionId: this.props.id,
      },
    });
    this.setState({
      requestId: redeem.data.redeemSuggestion.requestId,
    });
    this.setState({ submitted: true });
  }
}
const SuggestionWithGraphql = flowRight(
  graphql(REDEEM_SUGGESTION_MUTATION, {
    name: "redeemSuggestionMutation",
  }),
  graphql(SUGGESTION_QUERY, {
    name: "suggestionQuery",
    options: ({ id }) => ({
      variables: {
        token: localStorage.getItem(TOKEN),
        suggestionId: id,
      },
    }),
  })
)(Suggestion);
const SuggestionWithRouter = withRouter(SuggestionWithGraphql);

export default translate("Suggestion")(SuggestionWithRouter);
