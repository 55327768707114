export default {
  en: {
    App: {
      networkError: "Network error",
      tryAgain: "Try again",
      serverError: "Server error",
    },
    MainApp: {
      more: "More...",
      dashboard: "Dashboard",
      notActive: "Not active",
      active: "Active",
      servicePeriod: "Service period",
      credit: "credit",
      serviceEndDate: "Service end date",
      addCredit: "Add credit",
      chat: "Chat",
      requests: "Requests",
      settings: "Settings",
      logout: "Log out",
      termsAndConditions: "Terms & Conditions",
      privacyPolicy: "Privacy policy",
      newMessage: "New message",
      image: "Image",
      location: "Location",
      logoutConfirmationText: "Are you sure you want to log out?",
      logoutConfirmationTitle: "Log out",
      yes: "Yes",
      no: "No",
    },
    Dashboard: {
      notActive: "Not active",
      active: "Active",
      servicePeriod: "Service period",
      credit: "credit",
      serviceEndDate: "Service end date",
      requests: "Requests",
      liveFeed: "Live Feed",
      suggestions: "Suggested services",
    },
    DashboardSuggestion: {
      daysLeft: "days left",
    },
    Suggestion: {
      cancel: "Cancel",
      goBack: "Return",
      goToRequest: "Your request",
      askSophie: "Ask Sophie",
      availableUntil: "Active until",
      timesUsable: "Times usable",
      year: "year",
      month: "month",
      day: "day",
      hour: "hour",
      minute: "minute",
      years: "years",
      months: "months",
      days: "days",
      hours: "hours",
      minutes: "minutes",
    },
    Suggestions: {
      emptyTitle: "Suggested services",
      emptyText: "Your personal suggestions",
    },
    LiveFeed: {
      emptyTitle: "Live feed",
      emptyText: "Your updates on what we are doing for you",
      request: "Request",
      client: "Account",
      creditTransaction: "Credit",
      order: "Payment",
      suggestion: "Suggestion",
    },

    AddCredit: {
      dialog1: "How much do you want to add to your account?",
      dialog2: "Transaction costs differ based on payment method.",
      cancel: "Cancel",
      add: "Add",
      addCredit: "Add credit",
      amount: "Amount",
    },
    ChatImage: {
      saveToCameraRoll: "Save to camera roll",
      cancel: "Cancel",
    },
    Chat: {
      oneUnreadMsg: "1 unread message",
      unreadMessages: "unread messages",
      placeHolder: "Type a message...",
      camera: "Camera",
      image: "Image",
      location: "Location",
      cancel: "Cancel",
      sendAttachment: "Send attachment",
      cameraAccessRefused: "Camera access refused",
      copyText: "Copy text",
      yesterday: "Yesterday",
      today: "Today",
    },
    LoginPage: {
      phoneNumber: "Phone number",
      send: "Send",
      smsCode: "SMS code",
      invalidCode: "Invalid code",
    },
    RequestsList: {
      noRequestsWithStatus: "There are no {{status}} requests.",
      status_requestCreated: "Request created",
      status_updateExpected: "Update expected",
      status_waitingForPayment: "Waiting for payment",
      status_paymentDone: "Payment done",
      status_waitingForInput: "Waiting for input",
      status_proposalDone: "Proposal done",
      status_closeRequested: "Requested to close service",
      status_requestDone: "Request done",
    },
    Proposal: {
      option: "Choose",
      back: "Back",
      noneOfTheAbove: "New options",
    },
    Requests: {
      answer: "Answer",
      back: "Back",
      open: "Open",
      openInSentence: "open",
      closed: "Closed",
      closedInSentence: "closed",
      payment: "Payment",
      amount: "Amount",
      status: "Status",
      order_open: "Created",
      order_entered: "Entered",
      order_finalized: "Approved",
      order_paid: "Paid",
      order_delivering: "Delivering",
      order_delivered: "Delivered",
      order_closed: "Closed",
      order_canceled: "Cancelled",
      order_payment_type_asked: "Choice business/private",
      order_payment_method_asked: "Choice payment method",
      business: "Business",
      private: "Private",
      answerNeeded: "I need an answer from you",
      request_open: "Open",
      "request_waiting for customer": "I need reply from you",
      request_finished: "Finished",
      request_canceled: "Cancelled",
      description: "Description",
      step_Aangemaakt: "Created",
      "step_Opdracht geregistreerd": "Job registered",
      "step_Betaalverzoek verzonden": "Payment request sent",
      step_Betaald: "Paid",
      step_Afgesloten: "Closed",
      step_Geannuleerd: "Cancelled",
      step_Afgerond: "Finished",
      status_requestCreated: "Request created",
      status_updateExpected: "Update expected",
      status_waitingForPayment: "Waiting for payment",
      status_paymentDone: "Payment done",
      status_waitingForInput: "Waiting for input",
      status_proposalDone: "Proposal done",
      status_closeRequested: "Requested to close service",
      status_requestDone: "Request done",
      createdAt: "created",
      closedAt: "closed",
      emptyTitle: "Requests",
      emptyText: "A overview of your requests",
    },
    OrdersList: {
      amount: "Amount",
      description: "Description",
      price: "Price",
      includingCostsAmount: "(incl. €{{amount}} transaction costs)",
      progress: "Progress",
    },
    Settings: {
      webSessionHoursMaxExplanation:
        "How long do you want to stay you logged in? For safety reasons, the default is one hour.",
      "1hour": "1 hour",
      "1day": "1 day",
      "1week": "1 week",
      dailyExplanation: "Your daily message from my side.",
      sendAt: "Send at",
      appointments: "Appointments",
      requests: "Requests",
      weather: "Weather",
      news: "News",
      uTip: "uTIP",
      webSessionHoursMax: "Web session time",
      language: "Language",
      daily: "uDaily",
    },
  },
};
