let graphqlHttpUrl = 'http://localhost:4008/graphql';
if (process.env.REACT_APP_GRAPHQL_HOST && !process.env.REACT_APP_GRAPHQL_HOST.includes('localhost')) {
  graphqlHttpUrl = `https://${process.env.REACT_APP_GRAPHQL_HOST}/graphql`;
}

export default {
  graphqlHttpUrl: graphqlHttpUrl,
  graphqlWsUrl: graphqlHttpUrl.replace('http', 'ws'),
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  stripePublishableKey: process.env.REACT_APP_STRIPE_KEY,
}