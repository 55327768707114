import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class TextButtonWithProgress extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <Button
          size={this.props.size}
          disabled={this.props.disabled || this.props.busy}
          color={this.props.color}
          onClick={this.props.onClick}
          type={this.props.type}
          variant={this.props.variant}
        >
          {this.props.text}
        </Button>

        {this.props.busy && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "calc(50% - 12px)",
              left: "calc(50% - 12px)",
            }}
          />
        )}
      </div>
    );
  }
}
