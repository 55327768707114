import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { TOKEN } from "../constants";
import CenteredProgress from "./CenteredProgress";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatPrice } from "../helpers";
import moment from "moment";
import { translate } from "react-i18next";
import Button from "@material-ui/core/Button";
import { withRouter, Link } from "react-router-dom";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

const REQUEST_QUERY = gql`
  query($token: String!, $requestId: ID!) {
    request(token: $token, requestId: $requestId) {
      _id
      statusLog {
        status
        activeFrom
        activeUntil
        plannedAt
        linkedObjectId
      }
      steps {
        date
        name
      }
      title
      status
      businessPrivate
      createdAt
      orders {
        _id
        title
        totalPriceIncludingCosts
        status
      }
    }
  }
`;

const REQUEST_UPDATE_SUBSCRIPTION = gql`
  subscription($token: String!, $requestId: ID) {
    requestUpdate(token: $token, requestId: $requestId) {
      _id
      statusLog {
        status
        activeFrom
        activeUntil
        plannedAt
        linkedObjectId
      }
      steps {
        date
        name
      }
      title
      status
      businessPrivate
      createdAt
      orders {
        _id
        title
        totalPriceIncludingCosts
        status
      }
    }
  }
`;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    flexBasis: "50%",
  },
  tableCell: {
    paddingLeft: 0,
    paddingRight: 10,
  },
  button: {
    margin: 20,
  },
});

class Request extends Component {
  state = {
    tabValue: 0,
  };

  componentWillMount() {
    this.props.requestQuery.subscribeToMore({
      document: REQUEST_UPDATE_SUBSCRIPTION,
      variables: {
        token: localStorage.getItem(TOKEN),
        requestId: this.props.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        return {
          request: subscriptionData.data.requestUpdate,
        };
      },
    });
  }

  render() {
    const { classes, t } = this.props;

    if (this.props.requestQuery.loading) {
      return (
        <div
          style={{
            flex: 1,
            height: "100%",
          }}
        >
          <CenteredProgress />
        </div>
      );
    }

    const req = this.props.requestQuery.request;

    const statusItem = req.statusLog[req.statusLog.length - 1];

    return (
      <div style={{ height: "100%", marginTop: "8px" }}>
        <div className="paper">
          <div className="paper-header">
            <h3 style={{ margin: 0 }}>
              {req.title &&
                req.title.substring(0, 70) +
                  (req.title.length > 70 ? "..." : "")}
            </h3>
          </div>
          <div style={{ padding: "10px 20px" }}>
            {statusItem &&
            (statusItem.status === "proposalDone" ||
              statusItem.status === "waitingForInput") ? (
              <section>
                <div className="waiting-for-customer">
                  <PhosphorIcon
                    name="warning-circle"
                    className="icon"
                  />
                  <p>{t("answerNeeded")}</p>
                </div>
                <p>{req.businessPrivate ? t(req.businessPrivate) : ""}</p>
              </section>
            ) : (
              <p style={{ fontStyle: "italic", margin: 0, display: "block" }}>
                {t("request_" + req.status)}
                {" - "}
                {req.businessPrivate ? t(req.businessPrivate) : ""}
              </p>
            )}
          </div>
          <Stepper
            style={{ width: "100%" }}
            activeStep={-1}
            orientation="vertical"
          >
            {req.statusLog && req.statusLog.length > 0
              ? req.statusLog.map((log, index) => {
                  let iconName;
                  let color;

                  if (log.activeUntil) {
                    iconName = "check-circle";
                    color = "var(--groen)";
                  } else if (log.status === "updateExpected") {
                    iconName = "clock";
                    color = "#0b8fd6";
                  } else {
                    iconName = "question";
                    color = "#ff5c00";
                  }

                  const fromString = moment(log.activeFrom).format(
                    "DD-MM-YYYY hh:mm A"
                  );

                  let dateString = t("createdAt") + " " + fromString;

                  if (log.activeUntil) {
                    const untilString = moment(log.activeUntil).format(
                      "DD-MM-YYYY hh:mm A"
                    );
                    dateString += " - " + t("closedAt") + " " + untilString;

                    if (untilString === fromString) {
                      dateString = untilString;
                    }
                  }

                  let stepLabel = (
                    <StepLabel
                      StepIconComponent={() => (
                        <PhosphorIcon
                          name={iconName}
                          style={{ color, fontSize: 25 }}
                        />
                      
                      )}
                      completed={true}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {t("status_" + log.status)}
                          {log.plannedAt
                            ? ": " + moment(log.plannedAt).calendar()
                            : ""}
                          <br />
                          <span
                            style={{ textDecoration: "none", fontSize: 11 }}
                          >
                            {dateString}
                          </span>
                        </div>
                        {log.status === "proposalDone" && (
                          <Link
                            to={"/proposal/" + log.linkedObjectId}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              color="secondary"
                              className={classes.button}
                              style={{ margin: 0, marginLeft: 15 }}
                            >
                              {log.activeUntil ? t("open") : t("answer")}
                            </Button>
                          </Link>
                        )}
                      </div>
                    </StepLabel>
                  );

                  return <Step key={index}>{stepLabel}</Step>;
                })
              : req.steps.map(step => (
                  <Step key={step.name} completed={step.date !== null}>
                    <StepLabel>
                      {t("step_" + step.name)}
                      <br />
                      <span style={{ textDecoration: "none", fontSize: 11 }}>
                        {step.date
                          ? moment(step.date).format("DD-MM-YYYY hh:mm A")
                          : ""}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
          </Stepper>
          {req.orders.length > 0 && (
            <div style={{ maxWidth: 850, marginBottom: 20 }}>
              <Table style={{ margin: 20, width: "calc(100% - 40px)" }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      {t("payment")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("amount")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("status")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {req.orders.map(order => (
                    <TableRow key={order._id}>
                      <TableCell className={classes.tableCell}>
                        {order.title}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        &euro;{formatPrice(order.totalPriceIncludingCosts)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {t("order_" + order.status)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <Button
            color="primary"
            className={classes.button}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            {t("back")}
          </Button>
        </div>
      </div>
    );
  }
}

const RequestWithGraphql = flowRight(
  graphql(REQUEST_QUERY, {
    name: "requestQuery",
    options: ({ id }) => ({
      variables: { token: localStorage.getItem(TOKEN), requestId: id },
    }),
  })
)(withStyles(styles, { withTheme: true })(Request));

const RequestWithRouter = withRouter(RequestWithGraphql);

export default translate("Requests")(RequestWithRouter);
