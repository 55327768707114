import React, { useEffect, useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import moment from "moment";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import Typography from "@material-ui/core/Typography";

import { getToken } from "../constants";
import { formatPrice } from "../helpers";
import logo from "../assets/images/ubutler-logo.svg";

import { HeaderName } from "./HeaderName";

export const GET_SUPPORTED_CURRENCIES = gql`
    query {
        supportedCurrencies {
            name
            code
            symbol
        }
    }
`;

export const Header = ({ onAddCredit, onLogout, hideClientDetails = false }) => {
  const [client, setClient] = useState({});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const CLIENT_QUERY = gql`
      query($token: String!) {
          client(token: $token) {
              _id
              firstName
              lastName
              company {
                  name
              }
              currency
              paidUntil
              credit
              createdAt
          }
      }
  `;

  const { loading, error, data } = useQuery(CLIENT_QUERY, {
    variables: { token: getToken() },
  });


  useEffect(() => {
    if (error) {
      console.log("Error: ", error);
    } else if (!loading) {
      setClient(data.client);
    }
  });

  const { data: currencyData = {} } = useQuery(GET_SUPPORTED_CURRENCIES);
  const { supportedCurrencies = [] } = currencyData;
  const currencies = {
    EUR: {
      name: "Euro",
      code: "EUR",
      symbol: "€",
    },
  };

  for (const currency of supportedCurrencies) {
    currencies[currency.code] = currency;
  }

  const { symbol: currencySymbol } = currencies[client.currency] || {};

  return (
    <MainDiv>
      <div className="header-content">
        <img className="ubutler-logo-header" src={logo} />

        <HeaderName
          firstName={client.firstName}
          lastName={client.lastName}
          onClick={event => {
            setMenuAnchor(event.currentTarget);
            setMenuOpen(true);
          }}
        />

        <Menu
          id="menu-user"
          anchorEl={menuAnchor}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              transform: "translate(6px, -5px)",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          {!hideClientDetails && (
            <>
              <div
                style={{
                  paddingLeft: 17.5,
                  outline: "none",
                  fontFamily: "Muli",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "var(--donkergrijs)",
                  lineHeight: "12px",
                }}
              >
                {client.company ? <p>Company: {client.company.name}</p> : null}
                <p>
                  Subscription until{" "}
                  {moment(client.paidUntil).format("DD MMMM YYYY")}
                </p>

                {currencySymbol && (
                  <p>{`Credit: ${currencySymbol} ${formatPrice(client.credit)}`}</p>
                )}
              </div>

              <Divider />
            </>
          )}

          {!hideClientDetails && client.currency === currencies.EUR.code && (
            <MenuItem
              onClick={event => {
                event.preventDefault();
                setMenuOpen(false);
                onAddCredit();
              }}
              style={{ height: "16px" }}
            >
              <ListItemIcon>
                <AccountBalanceWalletOutlinedIcon />
              </ListItemIcon>
              <StyledListItemText text="Add Credit" />
            </MenuItem>
          )}
          <MenuItem
            onClick={event => {
              event.preventDefault();
              setMenuOpen(false);
              window.open("https://ubutler.nl/terms", "_blank");
            }}
            style={{ height: "16px" }}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <StyledListItemText text="Terms and Conditions" />
          </MenuItem>

          <MenuItem
            onClick={event => {
              event.preventDefault();
              setMenuOpen(false);
              window.open("https://ubutler.nl/terms", "_blank");
            }}
            style={{ height: "16px" }}
          >
            <ListItemIcon>
              <AssignmentLateOutlinedIcon />
            </ListItemIcon>
            <StyledListItemText text="Privacy Policy" />
          </MenuItem>

          <MenuItem
            onClick={event => {
              event.preventDefault();
              setMenuOpen(false);
              onLogout();
            }}
            style={{ height: "16px" }}
          >
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>

            <StyledListItemText text="Logout" />
          </MenuItem>
        </Menu>
      </div>
    </MainDiv>
  );
};

const StyledListItemText = ({ text }) => {
  return (
    <ListItemText
      disableTypography
      inset
      primary={
        <Typography
          variant="body1"
          style={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontFamily: "Muli",
            fontSize: "12px",
          }}
        >
          {text}
        </Typography>
      }
    />
  );
};

const MainDiv = styled.div`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.06);
  min-width: 820px;

  .header-content {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1194px;
    padding: 0px 27px;
  }

  .ubutler-logo-header {
    height: 27px;
  }
`;
