import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Navigation from "@material-ui/core/BottomNavigation";
import NavigationAction from "@material-ui/core/BottomNavigationAction";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";

export const SideBarNavigation = ({ pathname }) => {
  const history = useHistory();
  const [navigationValue, setNavigationValue] = useState(0);
  const navigationItems = [
    {
      label: "Dashboard",
      icon: <HomeOutlinedIcon />,
      route: "/",
    },
    {
      label: "Knowledge base",
      icon: <LibraryBooksOutlinedIcon />,
      route: "/topics",
    },
    {
      label: "Settings",
      icon: <SettingsOutlinedIcon />,
      route: "/settings",
    },
  ];

  useEffect(() => {
    navigationItems.forEach((item, index) => {
      if (pathname.startsWith(item.route)) {
        setNavigationValue(index);
      }
    });
  });

  return (
    <MainDiv>
      <Navigation
        showLabels
        value={navigationValue}
        onChange={(event, newNavigationValue) => {
          const newItem = navigationItems[newNavigationValue];

          if (!newItem.noFocus) {
            setNavigationValue(newNavigationValue);
          }

          if (newItem.onClick) {
            newItem.onClick(event);
          }

          if (newItem.route) {
            history.push(newItem.route);
          }
        }}
        showLabels
        style={{ borderBottom: "1px solid #d9e4eb" }}
      >
        {navigationItems.map((item, index) => (
          <NavigationAction
            classes={{ label: "label", selected: "selected" }}
            style={{
              fontWeight: "bold",
              fontSize: "6px",
              padding: 0,
              color: "var(--grijs)",
            }}
            label={item.label}
            icon={item.icon}
          />
        ))}
      </Navigation>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  .label {
    color: var(--donkergrijs);
    font-size: 10px;
  }

  .selected {
    color: var(--groen);
    border-bottom: 3px solid var(--groen);
  }

  .selected span {
    color: var(--groen);
    font-size: 10px;
    border-bottom: 0px;
  }
`;
