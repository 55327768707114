import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import dompurify from "dompurify";
import styled from "styled-components/macro";
import { Icon } from "./Icon/Icon";
import { LongButton } from "./LongButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ArrowForward from "@material-ui/icons/ArrowForward";

export const TodoButton = ({ title, url, policy, companyBranding }) => {
  const [displayPolicyDetails, setDisplayPolicyDetails] = useState(false);
  const history = useHistory();

  return (
    <LongButton
      title={title}
      onClick={() => history.push(url)}
      icon={<ArrowForward style={{ color: companyBranding.brandColor }} />}
      backgroundColor="#ffffff"
    >
      {policy && (
        <CompanyPolicy onClick={e => e.stopPropagation()}>
          <div
            onClick={() => setDisplayPolicyDetails(!displayPolicyDetails)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Icon name="info" size={20} color="#657786" />

            <div style={{ width: 8 }} />

            <PolicyTitle>Company policy: {policy.title}</PolicyTitle>

            <div style={{ flex: 1 }} />

            <Center>
              {!displayPolicyDetails && <ExpandMore className="expand" />}
              {displayPolicyDetails && <ExpandLess className="expand" />}
            </Center>
          </div>
          {displayPolicyDetails && (
            <CompanyPolicyInfo
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(policy.description),
              }}
            />
          )}
        </CompanyPolicy>
      )}
    </LongButton>
  );
};

const CompanyPolicy = styled.section`
  border-radius: 3px;
  border: solid 1px #e6ecf0;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 15px 15px;
  cursor: initial;

  padding: 8px 8px;
`;

const PolicyTitle = styled.div`
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #657786;
`;

const Center = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
`;

const CompanyPolicyInfo = styled.div`
  padding: 15px;
  padding-top: 10px;
  font-size: 16px;
  text-align: left;
`;
