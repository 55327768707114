import React from "react";
import { graphql } from "react-apollo";
import { getToken } from "../constants";
import { ChatWrapper } from "./ChatWrapper";
import gql from "graphql-tag";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

class RequestChatComponent extends React.Component {
  componentDidMount() {
    this.props.requestMessagesQuery.refetch();
  }

  renderChatHeader = () => (
    <React.Fragment>
      <PhosphorIcon 
      name="warning-circle"
      className="icon"
      style={{ marginRight: 5, color: "#42c175", opacity: 0.8 }}
      />
      Request chat is an experimental feature.
    </React.Fragment>
  );

  render() {
    const conversations =
      this.props?.requestMessagesQuery?.requestChatForClient ?? [];

    const messageArray = [];
    for (const { messages = [] } of conversations) {
      messageArray.push(...messages);
    }

    return (
      <ChatWrapper
        title="Request chat"
        messages={messageArray.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )}
        allowInput={false}
        chatHeader={this.renderChatHeader()}
      />
    );
  }
}

const REQUEST_MESSAGES_QUERY = gql`
  query($token: String!, $requestId: String!) {
    requestChatForClient(token: $token, requestId: $requestId) {
      _id
      messages {
        _id
        sentByUser
        clientId
        createdAt
        status
        externalId
        body {
          type
          text
          longitude
          latitude
          url
        }
        content
        options
        type
        latitude
        longitude
        url
        status
        externalId
      }
    }
  }
`;

export const RequestChat = graphql(REQUEST_MESSAGES_QUERY, {
  name: "requestMessagesQuery",
  options: ({ requestId }) => ({
    variables: {
      token: getToken(),
      requestId,
    },
    fetchPolicy: "network-only", // "cache-and-network" did not work.
  }),
})(RequestChatComponent);
