import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import gql from "graphql-tag";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import { TOKEN } from "../constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextButtonWithProgress from "./TextButtonWithProgress";
import { translate } from "react-i18next";

const ORIGIN = "web";

const styles = theme => ({});

class AddCredit extends Component {
  state = {
    amount: 10,
    error: false,
    redeemResult: null,
    submitting: false,
  };
  moveCaretAtEnd(e) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }
  render() {
    const { t } = this.props;
    return (
      <Dialog open={true} onClose={this.props.handleRequestClose}>
        <DialogTitle>{t("addCredit")}</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (!this.state.amount) {
              return;
            }

            this.submit();
          }}
        >
          <DialogContent>
            <DialogContentText>{t("dialog1")}</DialogContentText>
            <DialogContentText>{t("dialog2")}</DialogContentText>
            <TextField
              disabled={this.state.submitting}
              error={this.state.error}
              value={this.state.amount}
              autoFocus
              onFocus={this.moveCaretAtEnd}
              margin="dense"
              id="name"
              label={t("Amount")}
              type="number"
              fullWidth
              onChange={e => {
                this.setState({
                  amount: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleRequestClose}
              color="primary"
              disabled={this.state.submitting}
            >
              {t("cancel")}
            </Button>
            <TextButtonWithProgress
              busy={this.state.submitting}
              disabled={!this.state.amount}
              type="submit"
              color="primary"
              text={t("add")}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  submit = async () => {
    try {
      this.setState({ submitting: true });

      const { data } = await this.props.AddCreditMutation({
        variables: {
          amount: this.state.amount * 100,
          origin: ORIGIN,
        },
      });

      const paymentUrl = data.addAccountCredit;
      window.location.href = paymentUrl;
    } catch (e) {
      this.setState({ error: "Error adding credit", submitting: false });
    }
  };
}

const ADD_CREDIT_MUTATION = gql`
  mutation($token: String!, $amount: Int!, $origin: Origin!) {
    addAccountCredit(token: $token, amount: $amount, origin: $origin)
  }
`;

const AddCreditWithGraphql = flowRight(
  graphql(ADD_CREDIT_MUTATION, {
    name: "AddCreditMutation",
    options: ({ status }) => ({
      variables: { status, token: localStorage.getItem(TOKEN) },
    }),
  })
)(withStyles(styles)(AddCredit));

export default translate("AddCredit")(AddCreditWithGraphql);
